const classTeacherSubjectData = [
    {
        "class": "8A",
        "teacher": "Abdelraheem Kitana",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G8-A"
    },
    {
        "class": "8B",
        "teacher": "Abdelraheem Kitana",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G8-B"
    },
    {
        "class": "8C",
        "teacher": "Abdelraheem Kitana",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G8-C"
    },
    {
        "class": "8D",
        "teacher": "Alaa Mohammed",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G8-D"
    },
    {
        "class": "8E",
        "teacher": "Alaa Mohammed",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G8-E"
    },
    {
        "class": "8F",
        "teacher": "Alaa Mohammed",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G8-F"
    },
    {
        "class": "8G",
        "teacher": "Jelena Prepic",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G8-G"
    },
    {
        "class": "8H",
        "teacher": "Jelena Prepic",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G8-H"
    },
    {
        "class": "8I",
        "teacher": "Jelena Prepic",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G8-I"
    },
    {
        "class": "8J",
        "teacher": "Manal Naser",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G8-J"
    },
    {
        "class": "9A",
        "teacher": "Hamdy Elmasry",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G9-A"
    },
    {
        "class": "9B",
        "teacher": "Hamdy Elmasry",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G9-B"
    },
    {
        "class": "9C",
        "teacher": "Hamdy Elmasry",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G9-C"
    },
    {
        "class": "9D",
        "teacher": "Tamer Sakr",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G9-D"
    },
    {
        "class": "9E",
        "teacher": "Tamer Sakr",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G9-E"
    },
    {
        "class": "9F",
        "teacher": "Tamer Sakr",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G9-F"
    },
    {
        "class": "9G",
        "teacher": "Halim Rezk",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G9-G"
    },
    {
        "class": "9H",
        "teacher": "Halim Rezk",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G9-H"
    },
    {
        "class": "9I",
        "teacher": "Halim Rezk",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G9-I"
    },
    {
        "class": "9J",
        "teacher": "Hasan Shihan",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G9-J"
    },
    {
        "class": "9K",
        "teacher": "Hasan Shihan",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G9-K"
    },
    {
        "class": "10A",
        "teacher": "Ahmed Fawzy",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G10-A"
    },
    {
        "class": "10B",
        "teacher": "Ahmed Fawzy",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G10-B"
    },
    {
        "class": "10C",
        "teacher": "Ahmed Fawzy",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G10-C"
    },
    {
        "class": "10D",
        "teacher": "Ahmed Fawzy",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G10-D"
    },
    {
        "class": "10E",
        "teacher": "AbdulRahman Alsyouf",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G10-E"
    },
    {
        "class": "10F",
        "teacher": "AbdulRahman Alsyouf",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G10-F"
    },
    {
        "class": "10G",
        "teacher": "AbdulRahman Alsyouf",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G10-G"
    },
    {
        "class": "10H",
        "teacher": "AbdulRahman Alsyouf",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G10-H"
    },
    {
        "class": "11A",
        "teacher": "Mohammed Surwar",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G11-A"
    },
    {
        "class": "11B",
        "teacher": "Mohammed Surwar",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G11-B"
    },
    {
        "class": "11C",
        "teacher": "Mohammed Surwar",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G11-C"
    },
    {
        "class": "11D",
        "teacher": "Alaa Osama",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G11-D"
    },
    {
        "class": "11E",
        "teacher": "Alaa Osama",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G11-E"
    },
    {
        "class": "11F",
        "teacher": "Alaa Osama",
        "shortSubjectName": "ENG",
        "subject": "english",
        "homeroom": "G11-F"
    },
    {
        "class": "8A",
        "teacher": "Hazem Klouz",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G8-A"
    },
    {
        "class": "8B",
        "teacher": "Hazem Klouz",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G8-B"
    },
    {
        "class": "8C",
        "teacher": "Hazem Klouz",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G8-C"
    },
    {
        "class": "8D",
        "teacher": "Hazem Klouz",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G8-D"
    },
    {
        "class": "8E",
        "teacher": "Mohammad Khreis",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G8-E"
    },
    {
        "class": "8F",
        "teacher": "Mohammad Khreis",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G8-F"
    },
    {
        "class": "8G",
        "teacher": "Mohammad Khreis",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G8-G"
    },
    {
        "class": "8H",
        "teacher": "Mohammad Khreis",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G8-H"
    },
    {
        "class": "8I",
        "teacher": "Basem Aksh",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G8-I"
    },
    {
        "class": "8J",
        "teacher": "Ahmad Kilani",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G8-J"
    },
    {
        "class": "9A",
        "teacher": "Rania Metwally",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G9-A"
    },
    {
        "class": "9B",
        "teacher": "Rania Metwally",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G9-B"
    },
    {
        "class": "9C",
        "teacher": "Rania Metwally",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G9-C"
    },
    {
        "class": "9D",
        "teacher": "Rania Metwally",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G9-D"
    },
    {
        "class": "9E",
        "teacher": "Rania Metwally",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G9-E"
    },
    {
        "class": "9F",
        "teacher": "Philip Smith",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G9-F"
    },
    {
        "class": "9G",
        "teacher": "Philip Smith",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G9-G"
    },
    {
        "class": "9H",
        "teacher": "Rami Rababah",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G9-H"
    },
    {
        "class": "9I",
        "teacher": "Rami Rababah",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G9-I"
    },
    {
        "class": "9J",
        "teacher": "Mohamed Tarig",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G9-J"
    },
    {
        "class": "9K",
        "teacher": "Mohamed Tarig",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G9-K"
    },
    {
        "class": "10A",
        "teacher": "Salman Hailat",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G10-A"
    },
    {
        "class": "10B",
        "teacher": "Salman Hailat",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G10-B"
    },
    {
        "class": "10C",
        "teacher": "Salman Hailat",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G10-C"
    },
    {
        "class": "10D",
        "teacher": "Salman Hailat",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G10-D"
    },
    {
        "class": "10E",
        "teacher": "Salman Hailat",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G10-E"
    },
    {
        "class": "10F",
        "teacher": "Rami Rababah",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G10-F"
    },
    {
        "class": "10G",
        "teacher": "Rami Rababah",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G10-G"
    },
    {
        "class": "10H",
        "teacher": "Rami Rababah",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G10-H"
    },
    {
        "class": "11A",
        "teacher": "Basem Aksh",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G11-A"
    },
    {
        "class": "11B",
        "teacher": "Basem Aksh",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G11-B"
    },
    {
        "class": "11C",
        "teacher": "Basem Aksh",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G11-C"
    },
    {
        "class": "11D",
        "teacher": "Ahmad Kilani",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G11-D"
    },
    {
        "class": "11E",
        "teacher": "Ahmad Kilani",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G11-E"
    },
    {
        "class": "11F",
        "teacher": "Ahmad Kilani",
        "shortSubjectName": "MTH",
        "subject": "math",
        "homeroom": "G11-F"
    },
    {
        "class": "8A",
        "teacher": "Hanan Salloum",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G8-A"
    },
    {
        "class": "8B",
        "teacher": "Hanan Salloum",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G8-B"
    },
    {
        "class": "8C",
        "teacher": "Hanan Salloum",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G8-C"
    },
    {
        "class": "8D",
        "teacher": "Hanan Salloum",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G8-D"
    },
    {
        "class": "8E",
        "teacher": "Samer Dabous",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G8-E"
    },
    {
        "class": "8F",
        "teacher": "Samer Dabous",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G8-F"
    },
    {
        "class": "8G",
        "teacher": "Samer Dabous",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G8-G"
    },
    {
        "class": "8H",
        "teacher": "Mohamed Elbayyar",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G8-H"
    },
    {
        "class": "8I",
        "teacher": "Mohamed Elbayyar",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G8-I"
    },
    {
        "class": "8J",
        "teacher": "Mohamed Elbayyar",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G8-J"
    },
    {
        "class": "9A",
        "teacher": "Ajith Sasikumar",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G9-A"
    },
    {
        "class": "9B",
        "teacher": "Ajith Sasikumar",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G9-B"
    },
    {
        "class": "9C",
        "teacher": "Ajith Sasikumar",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G9-C"
    },
    {
        "class": "9D",
        "teacher": "Ajith Sasikumar",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G9-D"
    },
    {
        "class": "9E",
        "teacher": "Ajith Sasikumar",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G9-E"
    },
    {
        "class": "9F",
        "teacher": "Sashnee Peter",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G9-F"
    },
    {
        "class": "9G",
        "teacher": "Sashnee Peter",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G9-G"
    },
    {
        "class": "9H",
        "teacher": "Sashnee Peter",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G9-H"
    },
    {
        "class": "9I",
        "teacher": "Sashnee Peter",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G9-I"
    },
    {
        "class": "9J",
        "teacher": "Sashnee Peter",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G9-J"
    },
    {
        "class": "9K",
        "teacher": "Antoine Ghanem",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G9-K"
    },
    {
        "class": "10A",
        "teacher": "Muhammad Akhtar",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G10-A"
    },
    {
        "class": "10B",
        "teacher": "Muhammad Akhtar",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G10-B"
    },
    {
        "class": "10C",
        "teacher": "Muhammad Akhtar",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G10-C"
    },
    {
        "class": "10D",
        "teacher": "Muhammad Akhtar",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G10-D"
    },
    {
        "class": "10E",
        "teacher": "Muhammad Akhtar",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G10-E"
    },
    {
        "class": "10F",
        "teacher": "Antoine Ghanem",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G10-F"
    },
    {
        "class": "10G",
        "teacher": "Antoine Ghanem",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G10-G"
    },
    {
        "class": "10H",
        "teacher": "Antoine Ghanem",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G10-H"
    },
    {
        "class": "11A",
        "teacher": "Yousef Serrieh",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G11-A"
    },
    {
        "class": "11B",
        "teacher": "Mohamed Elbayyar",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G11-B"
    },
    {
        "class": "11C",
        "teacher": "Yousef Serrieh",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G11-C"
    },
    {
        "class": "11D",
        "teacher": "Yousef Serrieh",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G11-D"
    },
    {
        "class": "11E",
        "teacher": "Yousef Serrieh",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G11-E"
    },
    {
        "class": "11F",
        "teacher": "Yousef Serrieh",
        "shortSubjectName": "SCI",
        "subject": "science",
        "homeroom": "G11-F"
    },
    {
        "class": "8A",
        "teacher": "Nedal Ismail",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G8-A"
    },
    {
        "class": "8B",
        "teacher": "Nedal Ismail",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G8-B"
    },
    {
        "class": "8C",
        "teacher": "Nedal Ismail",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G8-C"
    },
    {
        "class": "8D",
        "teacher": "Nedal Ismail",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G8-D"
    },
    {
        "class": "8E",
        "teacher": "Mousab Althunaibat",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G8-E"
    },
    {
        "class": "8F",
        "teacher": "Mousab Althunaibat",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G8-F"
    },
    {
        "class": "8G",
        "teacher": "Mousab Althunaibat",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G8-G"
    },
    {
        "class": "8H",
        "teacher": "Mousab Althunaibat",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G8-H"
    },
    {
        "class": "8I",
        "teacher": "Hazem Elnaggar",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G8-I"
    },
    {
        "class": "8J",
        "teacher": "Hazem Elnaggar",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G8-J"
    },
    {
        "class": "9A",
        "teacher": "Hazem Elnaggar",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G9-A"
    },
    {
        "class": "9B",
        "teacher": "Hazem Elnaggar",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G9-B"
    },
    {
        "class": "9C",
        "teacher": "Nadia Mustafa",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G9-C"
    },
    {
        "class": "9D",
        "teacher": "Nadia Mustafa",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G9-D"
    },
    {
        "class": "9E",
        "teacher": "Nadia Mustafa",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G9-E"
    },
    {
        "class": "9F",
        "teacher": "Nadia Mustafa",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G9-F"
    },
    {
        "class": "9G",
        "teacher": "Nadia Mustafa",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G9-G"
    },
    {
        "class": "9H",
        "teacher": "Benyamin Alali",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G9-H"
    },
    {
        "class": "9I",
        "teacher": "Benyamin Alali",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G9-I"
    },
    {
        "class": "9J",
        "teacher": "Benyamin Alali",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G9-J"
    },
    {
        "class": "9K",
        "teacher": "Benyamin Alali",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G9-K"
    },
    {
        "class": "10A",
        "teacher": "Benyamin Alali",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G10-A"
    },
    {
        "class": "10B",
        "teacher": "Mahmoud Hussein",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G10-B"
    },
    {
        "class": "10C",
        "teacher": "Mahmoud Hussein",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G10-C"
    },
    {
        "class": "10D",
        "teacher": "Mahmoud Hussein",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G10-D"
    },
    {
        "class": "10E",
        "teacher": "Mahmoud Hussein",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G10-E"
    },
    {
        "class": "10F",
        "teacher": "Mahmoud Hussein",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G10-F"
    },
    {
        "class": "10G",
        "teacher": "Hassan Almasri",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G10-G"
    },
    {
        "class": "10H",
        "teacher": "Hassan Almasri",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G10-H"
    },
    {
        "class": "11A",
        "teacher": "Tamer Zahran",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G11-A"
    },
    {
        "class": "11B",
        "teacher": "Tamer Zahran",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G11-B"
    },
    {
        "class": "11C",
        "teacher": "Tamer Zahran",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G11-C"
    },
    {
        "class": "11D",
        "teacher": "Hassan Almasri",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G11-D"
    },
    {
        "class": "11E",
        "teacher": "Hassan Almasri",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G11-E"
    },
    {
        "class": "11F",
        "teacher": "Hassan Almasri",
        "shortSubjectName": "ARA",
        "subject": "arabic",
        "homeroom": "G11-F"
    },
    {
        "class": "8A",
        "teacher": "Ahmed Attia",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G8-A"
    },
    {
        "class": "8B",
        "teacher": "Ahmed Attia",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G8-B"
    },
    {
        "class": "8C",
        "teacher": "Ahmed Attia",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G8-C"
    },
    {
        "class": "8D",
        "teacher": "Ahmed Attia",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G8-D"
    },
    {
        "class": "8E",
        "teacher": "Ahmed Attia",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G8-E"
    },
    {
        "class": "8F",
        "teacher": "Ahmed Attia",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G8-F"
    },
    {
        "class": "8G",
        "teacher": "Ahmed Attia",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G8-G"
    },
    {
        "class": "8H",
        "teacher": "Ahmed Attia",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G8-H"
    },
    {
        "class": "8I",
        "teacher": "Ahmed Attia",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G8-I"
    },
    {
        "class": "8J",
        "teacher": "Ahmed Attia",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G8-J"
    },
    {
        "class": "9A",
        "teacher": "Khadeejeh Hussein",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G9-A"
    },
    {
        "class": "9B",
        "teacher": "Khadeejeh Hussein",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G9-B"
    },
    {
        "class": "9C",
        "teacher": "Khadeejeh Hussein",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G9-C"
    },
    {
        "class": "9D",
        "teacher": "Khadeejeh Hussein",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G9-D"
    },
    {
        "class": "9E",
        "teacher": "Khadeejeh Hussein",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G9-E"
    },
    {
        "class": "9F",
        "teacher": "Khadeejeh Hussein",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G9-F"
    },
    {
        "class": "9G",
        "teacher": "Khadeejeh Hussein",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G9-G"
    },
    {
        "class": "9H",
        "teacher": "Khadeejeh Hussein",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G9-H"
    },
    {
        "class": "9I",
        "teacher": "Khadeejeh Hussein",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G9-I"
    },
    {
        "class": "9J",
        "teacher": "Khadeejeh Hussein",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G9-J"
    },
    {
        "class": "9K",
        "teacher": "Othman Othman",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G9-K"
    },
    {
        "class": "10A",
        "teacher": "Othman Othman",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G10-A"
    },
    {
        "class": "10B",
        "teacher": "Othman Othman",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G10-B"
    },
    {
        "class": "10C",
        "teacher": "Othman Othman",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G10-C"
    },
    {
        "class": "10D",
        "teacher": "Othman Othman",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G10-D"
    },
    {
        "class": "10E",
        "teacher": "Othman Othman",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G10-E"
    },
    {
        "class": "10F",
        "teacher": "Othman Othman",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G10-F"
    },
    {
        "class": "10G",
        "teacher": "Othman Othman",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G10-G"
    },
    {
        "class": "10H",
        "teacher": "Othman Othman",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G10-H"
    },
    {
        "class": "11A",
        "teacher": "Hamad Shekaili",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G11-A"
    },
    {
        "class": "11B",
        "teacher": "Hamad Shekaili",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G11-B"
    },
    {
        "class": "11C",
        "teacher": "Hamad Shekaili",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G11-C"
    },
    {
        "class": "11D",
        "teacher": "Hamad Shekaili",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G11-D"
    },
    {
        "class": "11E",
        "teacher": "Hamad Shekaili",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G11-E"
    },
    {
        "class": "11F",
        "teacher": "Hamad Shekaili",
        "shortSubjectName": "ISL",
        "subject": "islamic",
        "homeroom": "G11-F"
    },
    {
        "class": "8A",
        "teacher": "Wael Radwan",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G8-A"
    },
    {
        "class": "8B",
        "teacher": "Wael Radwan",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G8-B"
    },
    {
        "class": "8C",
        "teacher": "Wael Radwan",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G8-C"
    },
    {
        "class": "8D",
        "teacher": "Wael Radwan",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G8-D"
    },
    {
        "class": "8E",
        "teacher": "Wael Radwan",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G8-E"
    },
    {
        "class": "8F",
        "teacher": "Wael Radwan",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G8-F"
    },
    {
        "class": "8G",
        "teacher": "Wael Radwan",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G8-G"
    },
    {
        "class": "8H",
        "teacher": "Wael Radwan",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G8-H"
    },
    {
        "class": "8I",
        "teacher": "Wael Radwan",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G8-I"
    },
    {
        "class": "8J",
        "teacher": "Salwa Khalifa",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G8-J"
    },
    {
        "class": "9A",
        "teacher": "Salwa Khalifa",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G9-A"
    },
    {
        "class": "9B",
        "teacher": "Salwa Khalifa",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G9-B"
    },
    {
        "class": "9C",
        "teacher": "Salwa Khalifa",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G9-C"
    },
    {
        "class": "9D",
        "teacher": "Salwa Khalifa",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G9-D"
    },
    {
        "class": "9E",
        "teacher": "Salwa Khalifa",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G9-E"
    },
    {
        "class": "9F",
        "teacher": "Salwa Khalifa",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G9-F"
    },
    {
        "class": "9G",
        "teacher": "Salwa Khalifa",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G9-G"
    },
    {
        "class": "9H",
        "teacher": "Salwa Khalifa",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G9-H"
    },
    {
        "class": "9I",
        "teacher": "Fayrouz Sabry",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G9-I"
    },
    {
        "class": "9J",
        "teacher": "Fayrouz Sabry",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G9-J"
    },
    {
        "class": "9K",
        "teacher": "Fayrouz Sabry",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G9-K"
    },
    {
        "class": "10A",
        "teacher": "Fayrouz Sabry",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G10-A"
    },
    {
        "class": "10B",
        "teacher": "Fayrouz Sabry",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G10-B"
    },
    {
        "class": "10C",
        "teacher": "Fayrouz Sabry",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G10-C"
    },
    {
        "class": "10D",
        "teacher": "Fayrouz Sabry",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G10-D"
    },
    {
        "class": "10E",
        "teacher": "Fayrouz Sabry",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G10-E"
    },
    {
        "class": "10F",
        "teacher": "Reem Talal",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G10-F"
    },
    {
        "class": "10G",
        "teacher": "Reem Talal",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G10-G"
    },
    {
        "class": "10H",
        "teacher": "Reem Talal",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G10-H"
    },
    {
        "class": "11A",
        "teacher": "Reem Talal",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G11-A"
    },
    {
        "class": "11B",
        "teacher": "Reem Talal",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G11-B"
    },
    {
        "class": "11C",
        "teacher": "Reem Talal",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G11-C"
    },
    {
        "class": "11D",
        "teacher": "Reem Talal",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G11-D"
    },
    {
        "class": "11E",
        "teacher": "Reem Talal",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G11-E"
    },
    {
        "class": "11F",
        "teacher": "Reem Talal",
        "shortSubjectName": "SOC",
        "subject": "social",
        "homeroom": "G11-F"
    }
];


export default classTeacherSubjectData;
