import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";

import CircularProgress from "@material-ui/core/CircularProgress";
import SheetOfAttendance from "./sheetOfAttendance";
import "./registrar.css";
import Portal from "./Portal";
import { Button } from "@material-ui/core";
import EndoFTermReport from "./EndoFTermReport";
const Registrar = () => {
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [CR, setCR] = useState(0);
  const [Ab, setAb] = useState(0);
  const [crNumber, setCrNumber] = useState(0);
  const [periodsArr, setPeriodsArr] = useState([]);
  const [classes, setClasses] = useState([]);
  const [dayTimetable, setDayTimetable] = useState([]);
  const [attData, setAttData] = useState([]);
  const [period1, setPeriod1] = useState([]);
  const [period2, setPeriod2] = useState([]);
  const [period3, setPeriod3] = useState([]);
  const [period4, setPeriod4] = useState([]);
  const [period5, setPeriod5] = useState([]);
  const [period6, setPeriod6] = useState([]);
  const [showPortal, setShowPortal] = useState(false);
  const [showPeriod, setShowPeriod] = useState(0);
  const [selectedClass, setSelectedClass] = useState("");
  const [homeroomid, setHomeroomid] = useState("");
  const [loading, setLoading] = useState(false);
  const [unBlockedStudent, setUnBlockedStudent] = useState();
  const [showUnboked, setShowUnboked] = useState(false);
  const [esis, setEsis] = useState();
  const [tablename, setTablename] = useState(
    "zatt" +
      new Date().toISOString().slice(0, 10).replace("-", "").replace("-", "")
  );
  const [selectedDate, setSelectedDate] = useState();
  const [stData, setStData] = useState({
    studentnumber: "",
    englishname: "",
    username: "",
    homroom: "",
    phonenumber: "",
    learningmode: "CR",
    homroomalph: "",
    nationality: "",
    grade_level: "",
    id: "",
    role: "student",
    alefpass: "",
    charteremail: "",
    charterpass: "",
    mobile: "",
    father: "",
    mother: "",
  });
 
  let p1Sum = 0;
  let p2Sum = 0;
  let p3Sum = 0;
  let p4Sum = 0;
  let p5Sum = 0;
  let p6Sum = 0;
  const [coverageSwitcher, setCoverageSwitcher] = useState(false);
  // const tablename =
  //   "zatt" +
  //   new Date().toISOString().slice(0, 10).replace("-", "").replace("-", "");
  const headers = [
    { label: "School ID", key: "schoolid" },
    { label: "Student Name", key: "studentname" },
    { label: "Pupil Number", key: "pupilnumber" },
    { label: "AM Absent/Late", key: "amabsentlate" },
    { label: "AM Reason", key: "amreason" },
    { label: "PM Absent/Late", key: "pmabsentlate" },
    { label: "PM Reason", key: "pmreason" },
    { label: "Attendance Mode", key: "attendancemode" },
    { label: "Attendance Pattern", key: "attendancepattern" },
    { label: "Homeroom ID", key: "homeroomid" },
    { label: "Homeroom Code", key: "homeroomcode" },
    { label: "Grade", key: "grade" },
    { label: "Group Code", key: "groupcode" },
  ];
  const classesArr = [
    "G10-A",
    "G10-B",
    "G10-C",
    "G10-D",
    "G10-E",
    "G10-F",
    "G10-G",
    "G7-A",
    "G7-B",
    "G7-C",
    "G7-D",
    "G7-E",
    "G7-F",
    "G7-G",
    "G7-H",
    "G7-I",
    "G7-J",
    "G7-K",
    "G8-A",
    "G8-B",
    "G8-C",
    "G8-D",
    "G8-E",
    "G8-F",
    "G8-G",
    "G8-H",
    "G8-I",
    "G8-J",
    "G8-K",
    "G8-L",
    "G9-A",
    "G9-B",
    "G9-C",
    "G9-D",
    "G9-E",
    "G9-F",
    "G9-G",
    "G9-H",
    "G9-I",
    "G9-J",
    "G9-K",
  ];

  const classIds = [
    "265053",
    "265059",
    "265060",
    "265061",
    "265062",
    "265063",
    "265064",
    "265054",
    "265069",
    "265070",
    "265071",
    "265072",
    "265073",
    "265074",
    "265075",
    "265076",
    "265077",
    "265087",
    "265055",
    "265078",
    "265079",
    "265080",
    "265081",
    "265082",
    "265083",
    "265084",
    "265085",
    "265088",
    "265089",
    "265669",
    "265090",
    "265056",
    "265057",
    "265058",
    "265091",
    "265092",
    "265093",
    "265670",
    "265671",
    "265672",
    "265673",
  ];
  const classData = [
    { class: "G8-A", grade: 8, pod: "A" },
    { class: "G8-B", grade: 8, pod: "A" },
    { class: "G8-C", grade: 8, pod: "A" },
    { class: "G8-D", grade: 8, pod: "A" },
    { class: "G8-E", grade: 8, pod: "B" },
    { class: "G8-F", grade: 8, pod: "B" },
    { class: "G8-G", grade: 8, pod: "B" },
    { class: "G8-H", grade: 8, pod: "C" },
    { class: "G8-I", grade: 8, pod: "C" },
    { class: "G8-J", grade: 8, pod: "C" },
    { class: "G9-A", grade: 9, pod: "F" },
    { class: "G9-B", grade: 9, pod: "F" },
    { class: "G9-C", grade: 9, pod: "F" },
    { class: "G9-D", grade: 9, pod: "F" },
    { class: "G9-E", grade: 9, pod: "G" },
    { class: "G9-F", grade: 9, pod: "G" },
    { class: "G9-G", grade: 9, pod: "G" },
    { class: "G9-H", grade: 9, pod: "G" },
    { class: "G9-I", grade: 9, pod: "H" },
    { class: "G9-J", grade: 9, pod: "H" },
    { class: "G9-K", grade: 9, pod: "H" },
    { class: "G10-A", grade: 10, pod: "D" },
    { class: "G10-B", grade: 10, pod: "D" },
    { class: "G10-C", grade: 10, pod: "D" },
    { class: "G10-D", grade: 10, pod: "D" },
    { class: "G10-E", grade: 10, pod: "E" },
    { class: "G10-F", grade: 10, pod: "E" },
    { class: "G10-G", grade: 10, pod: "E" },
    { class: "G10-H", grade: 10, pod: "E" },
    { class: "G11-A", grade: 11, pod: "I" },
    { class: "G11-B", grade: 11, pod: "I" },
    { class: "G11-C", grade: 11, pod: "I" },
    { class: "G11-D", grade: 11, pod: "J" },
    { class: "G11-E", grade: 11, pod: "J" },
    { class: "G11-F", grade: 11, pod: "J" },
  ];

  const Divstyle = {
    backgroundColor: "white",
    color: "black",
    margin: "0 50px",
    padding: "2px",
    borderRadius: "40%",
    width: "40%",
  };
  const getschoolAttendance = async (x) => {
    setData([]);
    attCheck(x);
    try {
      const body = {
        tablename: x,
      };
      const response = await fetch(
        "https://mut.herokuapp.com/getschoolAttendance",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const resp = await response.json();
      resp.forEach((e) => {
        if (e.p1 === "null") {
          e.p1 = 0;
        }
        if (e.p2 === "null") {
          e.p2 = 0;
        }
        if (e.p3 === "null") {
          e.p3 = 0;
        }
        if (e.p4 === "null") {
          e.p4 = 0;
        }
        if (e.p5 === "null") {
          e.p5 = 0;
        }
        if (e.p6 === "null") {
          e.p6 = 0;
        }
      });
      let crCounter = 0;
      resp.forEach((el) => {
        el.homeroomid = classIds[classesArr.indexOf(el.homeroomcode)];
        el.date = "";
        el.grade = el.homeroomcode[1];
        if (
          Number(el.p1) +
            Number(el.p2) +
            Number(el.p3) +
            Number(el.p4) +
            Number(el.p5) +
            Number(el.p6) >
          1
        ) {
          el.amabsentlate = "";
          el.amreason = "";
          el.pmabsentlate = "";
          el.pmreason = "";
          crCounter = crCounter + 1;
          el.attendancemode = "CR";
          el.attendancepattern = "PH";
          el.groupcode = "";
        } else {
          el.amabsentlate = "A";
          el.amreason = 30;
          el.pmabsentlate = "A";
          el.pmreason = 30;
          el.attendancemode = "CR";
          el.attendancepattern = "PH";
          el.groupcode = "";
        }
      });
     
      setCrNumber(crCounter);
      setData(resp);

      let CrCounter = 0;
      let AbCounter = 0;
      resp.forEach((e) => {
        if (e.attendancemode === "CR") {
          CrCounter = CrCounter + 1;
        }
        if (e.amabsentlate === "A") {
          AbCounter = AbCounter + 1;
        }
      });
      setCR(CrCounter);
      setAb(AbCounter);
    } catch (error) {
      console.log(error);
    }
  };
  const getschoolAttendance1 = async (x) => {
    setData([]);
    attCheck(x);
    try {
      const body = {
        tablename: x,
      };
      const response = await fetch(
        "https://mut.herokuapp.com/getschoolAttendance",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const resp = await response.json();
      resp.forEach((e) => {
        if (e.p1 === "null") {
          e.p1 = 0;
        }
        if (e.p2 === "null") {
          e.p2 = 0;
        }
        if (e.p3 === "null") {
          e.p3 = 0;
        }
        if (e.p4 === "null") {
          e.p4 = 0;
        }
        if (e.p5 === "null") {
          e.p5 = 0;
        }
        if (e.p6 === "null") {
          e.p6 = 0;
        }
      });
      let crCounter = 0;
      resp.forEach((el) => {
        el.homeroomid = classIds[classesArr.indexOf(el.homeroomcode)];
        el.date = "";
        el.grade = el.homeroomcode[1];
        if (
          Number(el.p1) +
            Number(el.p2) +
            Number(el.p3) +
            Number(el.p4) +
            Number(el.p5) +
            Number(el.p6) >
          0
        ) {
          el.amabsentlate = "";
          el.amreason = "";
          el.pmabsentlate = "";
          el.pmreason = "";
          crCounter = crCounter + 1;
          el.attendancemode = "CR";
          el.attendancepattern = "PH";
          el.groupcode = "";
        } else {
          el.amabsentlate = "A";
          el.amreason = 30;
          el.pmabsentlate = "A";
          el.pmreason = 30;
          el.attendancemode = "CR";
          el.attendancepattern = "PH";
          el.groupcode = "";
        }
      });
     
      setCrNumber(crCounter);
      setData1(resp);

      let CrCounter = 0;
      let AbCounter = 0;
      resp.forEach((e) => {
        if (e.attendancemode === "CR") {
          CrCounter = CrCounter + 1;
        }
        if (e.amabsentlate === "A") {
          AbCounter = AbCounter + 1;
        }
      });
      setCR(CrCounter);
      setAb(AbCounter);
    } catch (error) {
      console.log(error);
    }
  };

  const addingNewStudent = async () => {
    try {
      const body = {
        studentnumber: stData.studentnumber,
        englishname: stData.englishname,
        username: stData.username,
        homroom: stData.homroom,
        phonenumber: stData.phonenumber,
        learningmode: stData.learningmode,
        homroomalph: stData.homroomalph,
        nationality: stData.nationality,
        grade_level: stData.grade_level,
        id: stData.id,
        role: stData.role,
        alefpass: stData.alefpass,
        charteremail: stData.charteremail,
        charterpass: stData.charterpass,
        mobile: stData.mobile,
        father: stData.father,
        mother: stData.mother,
      };

      const response = await fetch(
        "https://mut.herokuapp.com/addingNewStudent",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const resp = await response.json();
      insertIntoMasterAttendance();
    } catch (error) {
      console.log(error);
    }
  };
  const insertIntoMasterAttendance = async () => {
    try {
      const body = {
        schoolid: "6023",
        studentname: stData.englishname,
        pupilnumber: stData.studentnumber,
        homeroomid,
        homeroomcode: stData.homroom,
        grade: stData.grade_level,
        arabicname: stData.username,
        tempclass: stData.homroom,
        status: "Classroom واقعي",
      };
      console.log(body);
      const response = await fetch(
        "https://mut.herokuapp.com/insertIntoMasterAttendance",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const resp = await response.json();
      setShowPortal(false);
      setData({
        studentnumber: "",
        englishname: "",
        username: "",
        homroom: "",
        phonenumber: "",
        learningmode: "CR",
        homroomalph: "",
        nationality: "",
        grade_level: "",
        id: "",
        role: "student",
        alefpass: "",
        charteremail: "",
        charterpass: "",
        mobile: "",
        father: "",
        mother: "",
      });
    } catch (error) {
      console.log(error);
    }
  };
  const getHomeroomId = async (x) => {
    try {
      const body = {
        homeroomcode: x,
      };
      const response = await fetch("https://mut.herokuapp.com/getHomeroomId", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();
      console.log(resp);
      setHomeroomid(resp.homeroomid);
    } catch (error) {
      console.log(error);
    }
  };
  const attCheck = async (x) => {
    try {
      const body = {
        tablename: x,
      };
      const response = await fetch("https://mut.herokuapp.com/attCheck", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();
      setAttData(resp);
    } catch (error) {
      console.log(error);
    }
  };
  const getClasses = async () => {
    try {
      const response = await fetch("https://mut.herokuapp.com/getClasses", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const resp = await response.json();
      let classarr = [];
      resp.forEach((e) => {
        classarr.push(e.homroom[1] + e.homroom[3]);
      });
      setClasses(classarr);
    } catch (error) {
      console.log(error);
    }
  };
  const saveAs = (blob) => {
    var elem = window.document.createElement("a");
    elem.href = blob;
    elem.download = "fileName";
    elem.style = "display:none;";
    (document.body || document.documentElement).appendChild(elem);
    if (typeof elem.click === "function") {
      elem.click();
    } else {
      elem.target = "_blank";
      elem.dispatchEvent(
        new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
        })
      );
    }
    URL.revokeObjectURL(elem.href);
    elem.remove();
  };
  const exportAsPicture = () => {
    var html = document.getElementsByTagName("HTML")[0];
    var body = document.getElementsByTagName("BODY")[0];
    var htmlWidth = html.clientWidth;
    var bodyWidth = body.clientWidth;
    var data = document.getElementById("capture2");
    var newWidth = data.scrollWidth - data.clientWidth;
    if (newWidth > data.clientWidth) {
      htmlWidth += newWidth;
      bodyWidth += newWidth;
    }
    html.style.width = htmlWidth + "px";
    body.style.width = bodyWidth + "px";
  };
  const getreportElig = async () => {
    setShowUnboked(false);
    setLoading(true);
    try {
      const body = {
        idqueryString: `select username, englishname,studentnumber,homroom,reportelig from students where studentnumber='${esis}'`,
      };
      const response = await fetch("https://mut.herokuapp.com/general", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();
      console.log(resp[0]);
      resp[0].reportelig = "Unblocked";
      setUnBlockedStudent(resp[0]);
      UpdatereportElig();
    } catch (error) {
      console.log(error);
    }
  };
  const UpdatereportElig = async () => {
    try {
      const body = {
        idqueryString: `update students set reportelig='yes' where studentnumber='${esis}'`,
      };
      const response = await fetch("https://mut.herokuapp.com/general", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();

      setLoading(false);
      setShowUnboked(true);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getClasses();
  }, []);

  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ marginBottom: "50px" }}>
        Date
        <input
          className="select"
          type="date"
          style={{ marginTop: "5vh" }}
          onChange={(e) => {
            setSelectedDate(e.target.value);
            setLoading(true);
            attCheck(
              "zatt" +
                e.target.value.slice(0, 10).replace("-", "").replace("-", "")
            );
            getschoolAttendance(
              "zatt" +
                e.target.value.slice(0, 10).replace("-", "").replace("-", "")
            );
            getschoolAttendance1(
              "zatt" +
                e.target.value.slice(0, 10).replace("-", "").replace("-", "")
            );
          }}
        ></input>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {data.length > 0 ? (
          <div>
            <div style={{ alignSelf: "center" }}>
              <CSVLink
                data={data1}
                headers={headers}
                filename={selectedDate + ".csv"}
              >
                After Period 1
              </CSVLink>
            </div>
            <br></br>
            <div style={{ alignSelf: "center" }}>
              <CSVLink
                data={data}
                headers={headers}
                filename={selectedDate + ".csv"}
              >
                Download me
              </CSVLink>
            </div>
            <table className="coverageTable">
              <thead>
                <tr>
                  <th>Date </th>
                  <th>Total number of students </th>
                  <th>Number attending in school </th>
                  {/* <th>Number remote learning </th> */}
                  <th>Number of absent students </th>
                  <th>Attendance % </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedDate}</td>
                  <>
                    <td>{data.length}</td>
                    {/* <td>{crNumber}</td> */}
                    <td>{data.length - Ab}</td>
                    <td>{Ab}</td>
                    <td>
                      {(((data.length - Ab) * 100) / data.length).toFixed(2) +
                        "%"}
                    </td>
                  </>
                </tr>
              </tbody>
            </table>
          </div>
        ) : loading && data.length === 0 ? (
          <div
            style={{
              margin: "50px 0px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          ""
        )}
      </div>
      {/* <div>
        {sessionStorage.getItem("user") === "Cobra" ? (
          <select
            style={{ margin: "10px  " }}
            onChange={(e) => {
              setCoverageSwitcher(false);
              let arr = [
                e.target.value + "1",
                e.target.value + "2",
                e.target.value + "3",
                e.target.value + "4",
                e.target.value + "5",
                e.target.value + "6",
              ];
              getDayTimetable(arr);
              setPeriodsArr(arr);
            }}
          >
            <option aria-label="None" value="">
              select day...
            </option>
            <option value="m">Monday</option>
            <option value="t">Tuesday</option>
            <option value="w">Wednesday</option>
            <option value="th">Thursday</option>
            <option value="f">Friday</option>
          </select>
        ) : (
          ""
        )}
      </div> */}

      <div style={{ display: "flex", justifyContent: "center" }}>
        {coverageSwitcher && (
          <div>
            <button
              onClick={() => {
                setCoverageSwitcher(false);
              }}
            >
              Hide
            </button>
            <select
              onChange={(e) => {
                setShowPeriod(e.target.value);
              }}
            >
              <option value={0}>Select Period</option>
              <option value={1}>P1</option>
              <option value={2}>P2</option>
              <option value={3}>P3</option>
              <option value={4}>P4</option>
              <option value={5}>P5</option>
              <option value={6}>P6</option>
            </select>
            <button
              onClick={() => {
                exportAsPicture();
              }}
            >
              Export
            </button>
            <table id="capture2">
              <thead>
                <tr>
                  <th>Class</th>
                  <th>P1</th>
                  {showPeriod > 1 && <th>P2</th>}
                  {showPeriod > 2 && <th>P3</th>}
                  {showPeriod > 3 && <th>P4</th>}
                  {showPeriod > 4 && <th>P5</th>}
                  {showPeriod > 5 && <th>P6</th>}
                </tr>
              </thead>
              <tbody>
                {classes.map((e) => {
                  return (
                    <tr>
                      <td>{e}</td>
                      {showPeriod > -1 && (
                        <>
                          {attData[classes.indexOf(e)].sump1 !== "taken" ? (
                            <td
                              style={{ backgroundColor: "red", color: "white" }}
                            >
                              {period1[classes.indexOf(e)]}
                            </td>
                          ) : (
                            <td
                              style={{
                                backgroundColor: "green",
                                color: "white",
                              }}
                            >
                              <span style={{ color: "green" }}>
                                {(p1Sum = 0)}
                              </span>
                              {period1[classes.indexOf(e)]}
                              {data
                                .filter(
                                  (d) =>
                                    d.tempclass ===
                                    "G" + e.slice(0, 1) + "-" + e.slice(-1)
                                )
                                .map((m) => {
                                  if (Number(m.p1) > 0) {
                                    p1Sum = Number(p1Sum) + 1;
                                  }
                                })}
                              <div style={Divstyle}>
                                {Math.trunc(
                                  (p1Sum * 100) /
                                    data.filter(
                                      (d) =>
                                        d.tempclass ===
                                        "G" + e.slice(0, 1) + "-" + e.slice(-1)
                                    ).length
                                ) + "%"}
                              </div>
                            </td>
                          )}
                        </>
                      )}
                      {showPeriod > 1 && (
                        <>
                          {" "}
                          {attData[classes.indexOf(e)].sump2 !== "taken" ? (
                            <td
                              style={{ backgroundColor: "red", color: "white" }}
                            >
                              {period2[classes.indexOf(e)]}
                            </td>
                          ) : (
                            <td
                              style={{
                                backgroundColor: "green",
                                color: "white",
                              }}
                            >
                              <span style={{ color: "green" }}>
                                {(p2Sum = 0)}
                              </span>
                              {period2[classes.indexOf(e)]}
                              {data
                                .filter(
                                  (d) =>
                                    d.tempclass ===
                                    "G" + e.slice(0, 1) + "-" + e.slice(-1)
                                )
                                .map((m) => {
                                  if (Number(m.p2) > 0) {
                                    p2Sum = Number(p2Sum) + 1;
                                  }
                                })}
                              <div style={Divstyle}>
                                {Math.trunc(
                                  (p2Sum * 100) /
                                    data.filter(
                                      (d) =>
                                        d.tempclass ===
                                        "G" + e.slice(0, 1) + "-" + e.slice(-1)
                                    ).length
                                ) + "%"}
                              </div>
                            </td>
                          )}
                        </>
                      )}
                      {showPeriod > 2 && (
                        <>
                          {" "}
                          {attData[classes.indexOf(e)].sump3 !== "taken" ? (
                            <td
                              style={{ backgroundColor: "red", color: "white" }}
                            >
                              {period3[classes.indexOf(e)]}
                            </td>
                          ) : (
                            <td
                              style={{
                                backgroundColor: "green",
                                color: "white",
                              }}
                            >
                              <span style={{ color: "green" }}>
                                {(p3Sum = 0)}
                              </span>
                              {period3[classes.indexOf(e)]}
                              {data
                                .filter(
                                  (d) =>
                                    d.tempclass ===
                                    "G" + e.slice(0, 1) + "-" + e.slice(-1)
                                )
                                .map((m) => {
                                  if (Number(m.p3) > 0) {
                                    p3Sum = Number(p3Sum) + 1;
                                  }
                                })}
                              <div style={Divstyle}>
                                {Math.trunc(
                                  (p3Sum * 100) /
                                    data.filter(
                                      (d) =>
                                        d.tempclass ===
                                        "G" + e.slice(0, 1) + "-" + e.slice(-1)
                                    ).length
                                ) + "%"}
                              </div>
                            </td>
                          )}
                        </>
                      )}
                      {showPeriod > 3 && (
                        <>
                          {" "}
                          {attData[classes.indexOf(e)].sump4 !== "taken" ? (
                            <td
                              style={{ backgroundColor: "red", color: "white" }}
                            >
                              {period4[classes.indexOf(e)]}
                            </td>
                          ) : (
                            <td
                              style={{
                                backgroundColor: "green",
                                color: "white",
                              }}
                            >
                              <span style={{ color: "green" }}>
                                {(p4Sum = 0)}
                              </span>
                              {period4[classes.indexOf(e)]}
                              {data
                                .filter(
                                  (d) =>
                                    d.tempclass ===
                                    "G" + e.slice(0, 1) + "-" + e.slice(-1)
                                )
                                .map((m) => {
                                  if (Number(m.p4) > 0) {
                                    p4Sum = Number(p4Sum) + 1;
                                  }
                                })}
                              <div style={Divstyle}>
                                {Math.trunc(
                                  (p4Sum * 100) /
                                    data.filter(
                                      (d) =>
                                        d.tempclass ===
                                        "G" + e.slice(0, 1) + "-" + e.slice(-1)
                                    ).length
                                ) + "%"}
                              </div>
                            </td>
                          )}
                        </>
                      )}
                      {showPeriod > 4 && (
                        <>
                          {" "}
                          {attData[classes.indexOf(e)].sump5 !== "taken" ? (
                            <td
                              style={{ backgroundColor: "red", color: "white" }}
                            >
                              {period5[classes.indexOf(e)]}
                            </td>
                          ) : (
                            <td
                              style={{
                                backgroundColor: "green",
                                color: "white",
                              }}
                            >
                              <span style={{ color: "green" }}>
                                {(p5Sum = 0)}
                              </span>
                              {period5[classes.indexOf(e)]}
                              {data
                                .filter(
                                  (d) =>
                                    d.tempclass ===
                                    "G" + e.slice(0, 1) + "-" + e.slice(-1)
                                )
                                .map((m) => {
                                  if (Number(m.p5) > 0) {
                                    p5Sum = Number(p5Sum) + 1;
                                  }
                                })}
                              <div style={Divstyle}>
                                {Math.trunc(
                                  (p5Sum * 100) /
                                    data.filter(
                                      (d) =>
                                        d.tempclass ===
                                        "G" + e.slice(0, 1) + "-" + e.slice(-1)
                                    ).length
                                ) + "%"}
                              </div>
                            </td>
                          )}
                        </>
                      )}
                      {showPeriod > 5 && (
                        <>
                          {" "}
                          {attData[classes.indexOf(e)].sump6 !== "taken" ? (
                            <td
                              style={{ backgroundColor: "red", color: "white" }}
                            >
                              {period6[classes.indexOf(e)]}
                            </td>
                          ) : (
                            <td
                              style={{
                                backgroundColor: "green",
                                color: "white",
                              }}
                            >
                              <span style={{ color: "green" }}>
                                {(p6Sum = 0)}
                              </span>
                              {period6[classes.indexOf(e)]}
                              {data
                                .filter(
                                  (d) =>
                                    d.tempclass ===
                                    "G" + e.slice(0, 1) + "-" + e.slice(-1)
                                )
                                .map((m) => {
                                  if (Number(m.p6) > 0) {
                                    p6Sum = Number(p6Sum) + 1;
                                  }
                                })}
                              <div style={Divstyle}>
                                {Math.trunc(
                                  (p6Sum * 100) /
                                    data.filter(
                                      (d) =>
                                        d.tempclass ===
                                        "G" + e.slice(0, 1) + "-" + e.slice(-1)
                                    ).length
                                ) + "%"}
                              </div>
                            </td>
                          )}
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {/* <SheetOfAttendance /> */}
      <div
        style={{
          width: "80%",
          marginLeft: "100px",
          display: "flex",
          justifyContent: "left",
        }}
      >
        {/* <Button
          variant="contained"
          color="primary"
          onClick={() => setShowPortal(true)}
        >
          Add New Student
        </Button> */}
      </div>
      {showPortal && (
        <Portal>
          <div style={{ textAlign: "center" }}>
            <form style={{ display: "flex", flexDirection: "column" }}>
              <h4> New Student</h4>
              <input
                className="addinStInput"
                type="text"
                placeholder="eSIS #"
                onChange={(e) => {
                  stData.studentnumber = e.target.value;
                  stData.charteremail =
                    "s" + e.target.value + "@charterschools.ae";
                  stData.charterpass = "AMS@" + e.target.value;
                }}
              ></input>
              <input
                type="text"
                className="addinStInput"
                placeholder="Name"
                onChange={(e) => {
                  stData.englishname = e.target.value;
                }}
              ></input>
              <input
                type="text"
                className="addinStInput"
                placeholder="الاسم"
                onChange={(e) => {
                  stData.username = e.target.value;
                }}
              ></input>
              <input
                type="number"
                className="addinStInput"
                placeholder="Emirates ID"
                onChange={(e) => {
                  stData.id = e.target.value;
                }}
              ></input>
              <select
                type="number"
                className="addinStInput"
                placeholder="Grade"
                onChange={(e) => {
                  stData.grade_level = e.target.value;
                  setSelectedClass(e.target.value);
                }}
              >
                <option value="">Grade</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
              </select>
              {selectedClass.length > 0 ? (
                <select
                  className="addinStInput"
                  onChange={(e) => {
                    stData.homroom = e.target.value;
                    stData.homroomalph = e.target.value;
                    getHomeroomId(e.target.value);
                  }}
                >
                  <option value="">Section .....</option>
                  {classes
                    .filter((c) => c.slice(0, 1) === selectedClass)
                    .map((el) => {
                      return (
                        <option key={el}>
                          {"G" + el.slice(0, 1) + "-" + el.slice(-1)}
                        </option>
                      );
                    })}
                </select>
              ) : (
                ""
              )}
              <input
                type="text"
                className="addinStInput"
                placeholder="phone1"
                onChange={(e) => {
                  stData.phonenumber = e.target.value;
                }}
              ></input>
              <input
                type="text"
                className="addinStInput"
                placeholder="phone2"
                onChange={(e) => {
                  stData.mobile = e.target.value;
                }}
              ></input>
              <input
                type="text"
                className="addinStInput"
                placeholder="phone3"
                onChange={(e) => {
                  stData.father = e.target.value;
                }}
              ></input>
              <input
                type="text"
                className="addinStInput"
                placeholder="phone4"
                onChange={(e) => {
                  stData.mother = e.target.value;
                }}
              ></input>
              <select className="addinStInput">
                <option value="">Nationality</option>
                <option value="Emirati">Emirati</option>
                <option value="Children of Emirati Mother">
                  Children of Emirati Mother
                </option>
                <option value="Arabs">Arabs</option>
                <option value="Gulf">Gulf</option>
                <option value="Other">Other</option>
              </select>
            </form>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setShowPortal(false)}
            >
              Close
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                if (
                  stData.studentnumber === "" ||
                  stData.englishname === "" ||
                  stData.username === "" ||
                  stData.homroom === "" ||
                  stData.phonenumber === "" ||
                  stData.nationality === "" ||
                  stData.grade_level === "" ||
                  stData.id === "" ||
                  stData.mobile === ""
                ) {
                  alert("Please Complete Missing Fields");
                } else {
                  addingNewStudent();
                }
              }}
            >
              save
            </Button>
          </div>
        </Portal>
      )}
    </div>
  );
};

export default Registrar;
