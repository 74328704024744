import React, { useState, useEffect } from "react";
import generalQuery from "../generalQuery";
import datesArr from "./timetableInt";
import CircularProgress from "@mui/material/CircularProgress";
import "./PodsIntervention.css"; // Add styles for the component here

const podMapping = {
  "G10-A": "D",
  "G10-B": "D",
  "G10-C": "D",
  "G10-D": "D",
  "G10-E": "E",
  "G10-F": "E",
  "G10-G": "E",
  "G10-H": "E",
  "G11-A": "I",
  "G11-B": "I",
  "G11-C": "I",
  "G11-D": "J",
  "G11-E": "J",
  "G11-F": "J",
  "G8-A": "A",
  "G8-B": "A",
  "G8-C": "A",
  "G8-D": "A",
  "G8-E": "B",
  "G8-F": "B",
  "G8-G": "B",
  "G8-H": "C",
  "G8-I": "C",
  "G8-J": "C",
  "G9-A": "F",
  "G9-B": "F",
  "G9-C": "F",
  "G9-D": "F",
  "G9-E": "G",
  "G9-F": "G",
  "G9-G": "G",
  "G9-H": "G",
  "G9-I": "H",
  "G9-J": "H",
  "G9-K": "H",
};

const PodsIntervention = () => {
  const [pods, setPods] = useState([]);
  const [selectedPod, setSelectedPod] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Set the available pods based on the podMapping and sort them
    const uniquePods = [...new Set(Object.values(podMapping))].sort();
    setPods(uniquePods);
  }, []);

  const handlePodChange = (event) => {
    setSelectedPod(event.target.value);
    setStudents([]); // Clear students when a new pod is selected
  };

  const handleDateChange = (event) => {
  // Clear students when a new date is selected
  };

  useEffect(() => {
    if (selectedPod && selectedDate) {
      setLoading(true);
      generalQuery(`select * from interventiongroups25`).then((results) => {
        const [day, date] = selectedDate.split(" ");
        const cluster = selectedDate.split("- ")[1];

        // Filter the results based on the selected pod and date/cluster
        const filteredStudents = results
          .filter(
            (student) =>
              podMapping[student.class] === selectedPod &&
              student.cluster === cluster
          )
          .map((student) => ({
            esis: student["esis#"],
            name: student.name,
            class: student.class,
            group: student.group,
            teacher: student.teacher,
            room: student.room,
          }));

        setStudents(filteredStudents);
        setLoading(false);
      });
    }
  }, [selectedPod, selectedDate]);

  return (
    <div className="pods-intervention">
      <div className="filters">
        <select
          className="select"
          value={selectedPod}
          onChange={handlePodChange}
        >
          <option value="">Select Pod</option>
          {pods.map((pod, index) => (
            <option key={index} value={pod}>
              {pod}
            </option>
          ))}
        </select>

        <select
          className="select"
          value={selectedDate}
          onChange={(e) => {
            setSelectedDate(e.target.value);
            setStudents([]);
          }}
        >
          <option>Select Date</option>
          {datesArr.map((dateObj, index) => (
            <option
              key={index}
              value={`${dateObj.day} ${dateObj.date} - ${dateObj.cluster}`}
            >
              {dateObj.day} {dateObj.date} - {dateObj.cluster}
            </option>
          ))}
        </select>
      </div>

      <div className="right-column">
        {loading ? (
          <div className="loader">
            <CircularProgress />
          </div>
        ) : students.length > 0 ? (
          <div className="students-table">
            <h3>Students</h3>
            <table>
              <thead>
                <tr>
                  <th>eSIS</th>
                  <th>Name</th>
                  <th>Class</th>
                  <th>Group</th>
                  <th>Teacher</th>
                  <th>Room</th>
                </tr>
              </thead>
              <tbody>
                {students
                  .sort((a, b) => (a.class > b.class ? 1 : -1))
                  .map((student, index) => (
                    <tr key={index}>
                      <td>{student.esis}</td>
                      <td>{student.name}</td>
                      <td>{student.class}</td>
                      <td>{student.group}</td>
                      <td>{student.teacher}</td>
                      <td>{student.room}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>Select a pod and date to see students.</p>
        )}
      </div>
    </div>
  );
};

export default PodsIntervention;
