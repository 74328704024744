const datesArr = [
  { day: "Monday", date: "14-Oct-24", cluster: "C1", week: 1 },
  { day: "Tuesday", date: "15-Oct-24", cluster: "C2", week: 1 },
  { day: "Thursday", date: "17-Oct-24", cluster: "C3", week: 1 },
  { day: "Monday", date: "21-Oct-24", cluster: "C1", week: 2 },
  { day: "Tuesday", date: "22-Oct-24", cluster: "C2", week: 2 },
  { day: "Wednesday", date: "23-Oct-24", cluster: "C3", week: 2 },
  { day: "Monday", date: "28-Oct-24", cluster: "C1", week: 3 },
  { day: "Tuesday", date: "29-Oct-24", cluster: "C2", week: 3 },
  { day: "Wednesday", date: "30-Oct-24", cluster: "C3", week: 3 },
  { day: "Monday", date: "04-Nov-24", cluster: "C1", week: 4 },
  { day: "Tuesday", date: "05-Nov-24", cluster: "C2", week: 4 },
  { day: "Wednesday", date: "06-Nov-24", cluster: "C3", week: 4 },
  { day: "Monday", date: "11-Nov-24", cluster: "C1", week: 5 },
  { day: "Tuesday", date: "12-Nov-24", cluster: "C2", week: 5 },
  { day: "Wednesday", date: "13-Nov-24", cluster: "C3", week: 5 },
  { day: "Monday", date: "18-Nov-24", cluster: "C1", week: 6 },
  { day: "Tuesday", date: "19-Nov-24", cluster: "C2", week: 6 },
  { day: "Wednesday", date: "20-Nov-24", cluster: "C3", week: 6 },
  { day: "Monday", date: "25-Nov-24", cluster: "C1", week: 7 },
  { day: "Tuesday", date: "26-Nov-24", cluster: "C2", week: 7 },
  { day: "Wednesday", date: "27-Nov-24", cluster: "C3", week: 7 },
  { day: "Monday", date: "06-Jan-25", cluster: "C1", week: 8 },
  { day: "Tuesday", date: "07-Jan-25", cluster: "C2", week: 8 },
  { day: "Wednesday", date: "08-Jan-25", cluster: "C3", week: 8 },
  { day: "Monday", date: "13-Jan-25", cluster: "C1", week: 9 },
  { day: "Tuesday", date: "14-Jan-25", cluster: "C2", week: 9 },
  { day: "Wednesday", date: "15-Jan-25", cluster: "C3", week: 9 },
  { day: "Monday", date: "20-Jan-25", cluster: "C1", week: 10 },
  { day: "Tuesday", date: "21-Jan-25", cluster: "C2", week: 10 },
  { day: "Wednesday", date: "22-Jan-25", cluster: "C3", week: 10 },
  { day: "Monday", date: "27-Jan-25", cluster: "C1", week: 11 },
  { day: "Tuesday", date: "28-Jan-25", cluster: "C2", week: 11 },
  { day: "Wednesday", date: "29-Jan-25", cluster: "C3", week: 11 },
  { day: "Monday", date: "03-Feb-25", cluster: "C1", week: 12 },
  { day: "Tuesday", date: "04-Feb-25", cluster: "C2", week: 12 },
  { day: "Wednesday", date: "05-Feb-25", cluster: "C3", week: 12 },
  { day: "Monday", date: "10-Feb-25", cluster: "C1", week: 13 },
  { day: "Tuesday", date: "11-Feb-25", cluster: "C2", week: 13 },
  { day: "Wednesday", date: "12-Feb-25", cluster: "C3", week: 13 },
  { day: "Monday", date: "17-Feb-25", cluster: "C1", week: 14 },
  { day: "Tuesday", date: "18-Feb-25", cluster: "C2", week: 14 },
  { day: "Wednesday", date: "19-Feb-25", cluster: "C3", week: 14 },
  { day: "Monday", date: "24-Feb-25", cluster: "C1", week: 15 },
  { day: "Tuesday", date: "25-Feb-25", cluster: "C2", week: 15 },
  { day: "Wednesday", date: "26-Feb-25", cluster: "C3", week: 15 },
  { day: "Monday", date: "14-Apr-25", cluster: "C1", week: 16 },
  { day: "Tuesday", date: "15-Apr-25", cluster: "C2", week: 16 },
  { day: "Wednesday", date: "16-Apr-25", cluster: "C3", week: 16 },
  { day: "Monday", date: "21-Apr-25", cluster: "C1", week: 17 },
  { day: "Tuesday", date: "22-Apr-25", cluster: "C2", week: 17 },
  { day: "Wednesday", date: "23-Apr-25", cluster: "C3", week: 17 },
  { day: "Monday", date: "28-Apr-25", cluster: "C1", week: 18 },
  { day: "Tuesday", date: "29-Apr-25", cluster: "C2", week: 18 },
  { day: "Wednesday", date: "30-Apr-25", cluster: "C3", week: 18 },
  { day: "Monday", date: "05-May-25", cluster: "C1", week: 19 },
  { day: "Tuesday", date: "06-May-25", cluster: "C2", week: 19 },
  { day: "Wednesday", date: "07-May-25", cluster: "C3", week: 19 },
  { day: "Monday", date: "12-May-25", cluster: "C1", week: 20 },
  { day: "Tuesday", date: "13-May-25", cluster: "C2", week: 20 },
  { day: "Wednesday", date: "14-May-25", cluster: "C3", week: 20 },
  { day: "Monday", date: "19-May-25", cluster: "C1", week: 21 },
  { day: "Tuesday", date: "20-May-25", cluster: "C2", week: 21 },
  { day: "Wednesday", date: "21-May-25", cluster: "C3", week: 21 },
  { day: "Monday", date: "26-May-25", cluster: "C1", week: 22 },
  { day: "Tuesday", date: "27-May-25", cluster: "C2", week: 22 },
  { day: "Wednesday", date: "28-May-25", cluster: "C3", week: 22 }
];

export default datesArr;
