const amplify_usersData = [
    {
        "eSIS": 149253,
        "Name": "Ibrahim Ahmed Mohamed Ali Alhammadi",
        "Class": "G10-H",
        "Username": "s149253@charterschools.ae",
        "Password": "fairy21"
    },
    {
        "eSIS": 254897,
        "Name": "AHMED MOHAMED KHAMIS KHALFAN ALJABERI",
        "Class": "G10-C",
        "Username": "s254897@charterschools.ae",
        "Password": "melon10"
    },
    {
        "eSIS": 304142,
        "Name": "BUTTI ABDULLA KHAMIS ALI ALBUTEENI",
        "Class": "G10-B",
        "Username": "s304142@charterschools.ae",
        "Password": "leek20"
    },
    {
        "eSIS": 304187,
        "Name": "NASSER SALAH ALDEEN ALI AL FAKIH",
        "Class": "G10-E",
        "Username": "s304187@charterschools.ae",
        "Password": "swan17"
    },
    {
        "eSIS": 304556,
        "Name": "SALIM SULAIMAN SALIM AHMED ALJAEEDI",
        "Class": "G10-D",
        "Username": "s304556@charterschools.ae",
        "Password": "dumpling57"
    },
    {
        "eSIS": 309151,
        "Name": "AHMED MARWAN AHMED BALAMA ALTAMIMI",
        "Class": "G10-A",
        "Username": "s309151@charterschools.ae",
        "Password": "seaweed33"
    },
    {
        "eSIS": 317032,
        "Name": "AHMED SALEH TUWAIRED SALEM ALMENHALI",
        "Class": "G10-D",
        "Username": "s317032@charterschools.ae",
        "Password": "shark82"
    },
    {
        "eSIS": 317907,
        "Name": "Saeed Hamad Mohamed Mubarak Awadh Al Menhali",
        "Class": "G10-H",
        "Username": "s317907@charterschools.ae",
        "Password": "burrito59"
    },
    {
        "eSIS": 318398,
        "Name": "ABDULLA SAEED OBAID ALTHEEB AL-ALI",
        "Class": "G10-E",
        "Username": "s318398@charterschools.ae",
        "Password": "hat37"
    },
    {
        "eSIS": 318429,
        "Name": "SAOUD MOHAMED JUOAIL YAHWIL ALHEMEIRI",
        "Class": "G10-F",
        "Username": "s318429@charterschools.ae",
        "Password": "run72"
    },
    {
        "eSIS": 407235,
        "Name": "MOHAMED ABDULLA HASAN ABDULLA AL-ALI",
        "Class": "G10-B",
        "Username": "s407235@charterschools.ae",
        "Password": "princess44"
    },
    {
        "eSIS": 502079,
        "Name": "SELAIM ABDULLA MOHSIN SALEM ALMUHARRAMI",
        "Class": "G10-D",
        "Username": "s502079@charterschools.ae",
        "Password": "frosting16"
    },
    {
        "eSIS": 530932,
        "Name": "YAHYA SAKHIRI",
        "Class": "G10-C",
        "Username": "s530932@charterschools.ae",
        "Password": "flute21"
    },
    {
        "eSIS": 551855,
        "Name": "ABDULLA MUBARAK MOHAMED JABER ALMENHALI",
        "Class": "G10-C",
        "Username": "s551855@charterschools.ae",
        "Password": "fall27"
    },
    {
        "eSIS": 563783,
        "Name": "BADR MOHAMMED SALEM BIN ABRI",
        "Class": "G10-G",
        "Username": "s563783@charterschools.ae",
        "Password": "spring57"
    },
    {
        "eSIS": 564112,
        "Name": "Yousef Abdulaziz Ahmed Mohamed Al Marzooqi",
        "Class": "G10-B",
        "Username": "s564112@charterschools.ae",
        "Password": "pigeon14"
    },
    {
        "eSIS": 564660,
        "Name": "rashed ali aidaroos a bdulqader al jelani",
        "Class": "G10-E",
        "Username": "s564660@charterschools.ae",
        "Password": "shirt13"
    },
    {
        "eSIS": 564719,
        "Name": "Mabkhoot Musallam Al Sharqi Mabkhoot Al Menhali",
        "Class": "G10-D",
        "Username": "s564719@charterschools.ae",
        "Password": "lemon52"
    },
    {
        "eSIS": 564893,
        "Name": "SALEH AHMED SALMAN YOUSEF ALHAMMADI",
        "Class": "G10-E",
        "Username": "s564893@charterschools.ae",
        "Password": "pickle30"
    },
    {
        "eSIS": 564897,
        "Name": "SALEH KHALEEFA SALEH SAEED ALMANSOORI",
        "Class": "G10-A",
        "Username": "s564897@charterschools.ae",
        "Password": "yummy54"
    },
    {
        "eSIS": 564903,
        "Name": "MABKHOOT MOHAMED MABKHOOT ABDULLA ALMENHALI",
        "Class": "G10-F",
        "Username": "s564903@charterschools.ae",
        "Password": "horse64"
    },
    {
        "eSIS": 564926,
        "Name": "OMAR KHALED OMAR AHMED ALMASHJARI",
        "Class": "G10-D",
        "Username": "s564926@charterschools.ae",
        "Password": "olive80"
    },
    {
        "eSIS": 564972,
        "Name": "SALEH SULTAN SALEM SULTAN ALSAEDI",
        "Class": "G10-C",
        "Username": "s564972@charterschools.ae",
        "Password": "rabbit32"
    },
    {
        "eSIS": 564997,
        "Name": "ZAYED NASSER AWADH HUSSAIN ALMESSABI",
        "Class": "G10-F",
        "Username": "s564997@charterschools.ae",
        "Password": "space17"
    },
    {
        "eSIS": 565001,
        "Name": "Khaled Nayef Awadh Husain Almessabi",
        "Class": "G10-C",
        "Username": "s565001@charterschools.ae",
        "Password": "jungle73"
    },
    {
        "eSIS": 565333,
        "Name": "RASHED MOHAMMED SAEED SALEM AL SUDAIN",
        "Class": "G10-E",
        "Username": "s565333@charterschools.ae",
        "Password": "quack53"
    },
    {
        "eSIS": 565406,
        "Name": "FALAH OMAR AHMED OMAR ALBREIKI",
        "Class": "G10-H",
        "Username": "s565406@charterschools.ae",
        "Password": "slug38"
    },
    {
        "eSIS": 566275,
        "Name": "HAMAD MOHMED SALEM TAHI AL RAHEDI",
        "Class": "G10-F",
        "Username": "s566275@charterschools.ae",
        "Password": "river91"
    },
    {
        "eSIS": 566325,
        "Name": "AHMED MABKHOOT SULTAN ALRASHDI",
        "Class": "G10-C",
        "Username": "s566325@charterschools.ae",
        "Password": "corndog67"
    },
    {
        "eSIS": 566409,
        "Name": "RASHED MOHAMED MOHSEN NASER ALNUAIMI",
        "Class": "G10-D",
        "Username": "s566409@charterschools.ae",
        "Password": "cactus18"
    },
    {
        "eSIS": 566599,
        "Name": "Salem Mohamed Musallam Saeed Al Mansoori",
        "Class": "G10-F",
        "Username": "s566599@charterschools.ae",
        "Password": "turtle39"
    },
    {
        "eSIS": 566606,
        "Name": "Salem Hamad Salem Saleh Al Menhali",
        "Class": "G10-A",
        "Username": "s566606@charterschools.ae",
        "Password": "wolf98"
    },
    {
        "eSIS": 566624,
        "Name": "SAEED TALAL SAEED ALI ALMUHARRAMI",
        "Class": "G10-D",
        "Username": "s566624@charterschools.ae",
        "Password": "milk87"
    },
    {
        "eSIS": 566683,
        "Name": "MOHAMMED ALI HADI MOHAMED ALHAMMAMI",
        "Class": "G10-A",
        "Username": "s566683@charterschools.ae",
        "Password": "toad84"
    },
    {
        "eSIS": 566691,
        "Name": "Mubarak Saif Mohamed Musallam Salem Al Menhali",
        "Class": "G10-A",
        "Username": "s566691@charterschools.ae",
        "Password": "carp10"
    },
    {
        "eSIS": 567106,
        "Name": "HAMDAN MOHAMED AHMED AL JABERI",
        "Class": "G10-B",
        "Username": "s567106@charterschools.ae",
        "Password": "cherry46"
    },
    {
        "eSIS": 567167,
        "Name": "SEAHEL NASSER SULAIYAM RASHID ALGHAFRI",
        "Class": "G10-G",
        "Username": "s567167@charterschools.ae",
        "Password": "cheese67"
    },
    {
        "eSIS": 567183,
        "Name": "ALI RAMADAN BEKHIT SOLIMAN",
        "Class": "G10-C",
        "Username": "s567183@charterschools.ae",
        "Password": "black76"
    },
    {
        "eSIS": 567290,
        "Name": "SULTAN THABET HUSAIN QASIM ALKALDI",
        "Class": "G10-C",
        "Username": "s567290@charterschools.ae",
        "Password": "shadow36"
    },
    {
        "eSIS": 567456,
        "Name": "YOUSUF AAMER YOUSUF ABDULLA AL-ALI",
        "Class": "G10-B",
        "Username": "s567456@charterschools.ae",
        "Password": "mule76"
    },
    {
        "eSIS": 567620,
        "Name": "MOHAMMED ABDULLAH ABBOD BIN SAAD",
        "Class": "G10-E",
        "Username": "s567620@charterschools.ae",
        "Password": "light57"
    },
    {
        "eSIS": 567623,
        "Name": "AHMED ABDULLAH ABBOD BIN SAAD",
        "Class": "G10-A",
        "Username": "s567623@charterschools.ae",
        "Password": "ketchup47"
    },
    {
        "eSIS": 567640,
        "Name": "Zayed Mahdi Naji Hadi Alharthi",
        "Class": "G10-A",
        "Username": "s567640@charterschools.ae",
        "Password": "canary87"
    },
    {
        "eSIS": 567871,
        "Name": "KHALED MUBARAK AHMED MUBARAK ALMESSABI",
        "Class": "G10-F",
        "Username": "s567871@charterschools.ae",
        "Password": "pirate58"
    },
    {
        "eSIS": 567904,
        "Name": "ABDULLA MUSALLAM AWADH SHARAN ALMENHALI",
        "Class": "G10-F",
        "Username": "s567904@charterschools.ae",
        "Password": "ladybug15"
    },
    {
        "eSIS": 568120,
        "Name": "ALBAKHEET SAEED BAKHEET SALEEM ALMENHALI",
        "Class": "G10-C",
        "Username": "s568120@charterschools.ae",
        "Password": "bread45"
    },
    {
        "eSIS": 568543,
        "Name": "NASER SAEED SALEM SAEED ALHAJERI",
        "Class": "G10-G",
        "Username": "s568543@charterschools.ae",
        "Password": "raccoon43"
    },
    {
        "eSIS": 568597,
        "Name": "RAAD MOHAMED OMAIR MOHAMED ALHARTHI",
        "Class": "G10-C",
        "Username": "s568597@charterschools.ae",
        "Password": "tomato88"
    },
    {
        "eSIS": 568601,
        "Name": "Musabah Amer Musabah Alabd Almenhali",
        "Class": "G10-B",
        "Username": "s568601@charterschools.ae",
        "Password": "dollar32"
    },
    {
        "eSIS": 568607,
        "Name": "SALEM MUSAEED ALI AL HARETHI",
        "Class": "G10-B",
        "Username": "s568607@charterschools.ae",
        "Password": "moo16"
    },
    {
        "eSIS": 568638,
        "Name": "Mohammed Arif Ahmed Husain Al Saatari",
        "Class": "G10-C",
        "Username": "s568638@charterschools.ae",
        "Password": "panther48"
    },
    {
        "eSIS": 568746,
        "Name": "Omar Yousof Mesabah Saeed Al Hattali",
        "Class": "G10-C",
        "Username": "s568746@charterschools.ae",
        "Password": "yeti39"
    },
    {
        "eSIS": 568751,
        "Name": "ZEEN THEYAB ALSHAREEFZAIN SALEM ALHARTHI",
        "Class": "G10-E",
        "Username": "s568751@charterschools.ae",
        "Password": "pork63"
    },
    {
        "eSIS": 568853,
        "Name": "NASSER SALEH M ALSAIARI",
        "Class": "G10-B",
        "Username": "s568853@charterschools.ae",
        "Password": "panther76"
    },
    {
        "eSIS": 568867,
        "Name": "KOWAIDA SALEM OMAR KARAMA ALMENHALI",
        "Class": "G10-G",
        "Username": "s568867@charterschools.ae",
        "Password": "shirt27"
    },
    {
        "eSIS": 568868,
        "Name": "HUMAID SAEED HAMAD ALASHAT ALMENHALI",
        "Class": "G10-C",
        "Username": "s568868@charterschools.ae",
        "Password": "bison97"
    },
    {
        "eSIS": 568991,
        "Name": "SAAD MOHAMED SHAYA SAAD ALHAJERI",
        "Class": "G10-G",
        "Username": "s568991@charterschools.ae",
        "Password": "smart86"
    },
    {
        "eSIS": 569090,
        "Name": "MANSOOR SALEH ABDURABBO SALEH ALMESSABI",
        "Class": "G10-C",
        "Username": "s569090@charterschools.ae",
        "Password": "mars86"
    },
    {
        "eSIS": 569137,
        "Name": "RAKAN YAHYA SALEH AYEDH ALHARTHI",
        "Class": "G10-G",
        "Username": "s569137@charterschools.ae",
        "Password": "amazing79"
    },
    {
        "eSIS": 569167,
        "Name": "Bakheet Khalid Abdulla Saeed Al Menhali",
        "Class": "G10-A",
        "Username": "s569167@charterschools.ae",
        "Password": "whale90"
    },
    {
        "eSIS": 569208,
        "Name": "hamed muatasem hashem aldhahab aljneibi",
        "Class": "G10-E",
        "Username": "s569208@charterschools.ae",
        "Password": "turkey12"
    },
    {
        "eSIS": 569308,
        "Name": "SALEH MOHAMED SALEH BINSAADOON ALSEIARI",
        "Class": "G10-C",
        "Username": "s569308@charterschools.ae",
        "Password": "cookie14"
    },
    {
        "eSIS": 569309,
        "Name": "MOHAMMED ABDUL QADER MOHAMMED AL HEMYARI",
        "Class": "G10-A",
        "Username": "s569309@charterschools.ae",
        "Password": "popsicle22"
    },
    {
        "eSIS": 569429,
        "Name": "MUSALLAM SALEH MUSALLAM KEDAIRI ALMENHALI",
        "Class": "G10-B",
        "Username": "s569429@charterschools.ae",
        "Password": "minnow11"
    },
    {
        "eSIS": 569466,
        "Name": "JASSAR HUSAIN AHMED NASER ALHARTHI",
        "Class": "G10-D",
        "Username": "s569466@charterschools.ae",
        "Password": "guitar70"
    },
    {
        "eSIS": 569476,
        "Name": "THABIT OMAR THABET NAHADI ALMENHALI",
        "Class": "G10-D",
        "Username": "s569476@charterschools.ae",
        "Password": "squid13"
    },
    {
        "eSIS": 569563,
        "Name": "KHALED MOHAMED NASER HUSSAIN ALNUAIMI",
        "Class": "G10-D",
        "Username": "s569563@charterschools.ae",
        "Password": "bark21"
    },
    {
        "eSIS": 569620,
        "Name": "HAMED ABDULLA AHMED HUSAIN AL MATHARI",
        "Class": "G10-D",
        "Username": "s569620@charterschools.ae",
        "Password": "buffalo64"
    },
    {
        "eSIS": 569650,
        "Name": "AHMED MOHAMED EBRAHIM OBAID ALMANSOORI",
        "Class": "G10-C",
        "Username": "s569650@charterschools.ae",
        "Password": "happy41"
    },
    {
        "eSIS": 569955,
        "Name": "QASEM NASER QASEM NASER BINAMRO",
        "Class": "G10-H",
        "Username": "s569955@charterschools.ae",
        "Password": "run87"
    },
    {
        "eSIS": 569956,
        "Name": "HAZZAA ALI AHMED ALRUBAEI ALMANSOORI",
        "Class": "G10-E",
        "Username": "s569956@charterschools.ae",
        "Password": "history64"
    },
    {
        "eSIS": 569978,
        "Name": "KHALID ALI SAEED SALEH ALJABERI",
        "Class": "G10-G",
        "Username": "s569978@charterschools.ae",
        "Password": "lilac10"
    },
    {
        "eSIS": 569995,
        "Name": "ABDULLA SALEH BAKHEET SALEM ALMANSOORI",
        "Class": "G10-C",
        "Username": "s569995@charterschools.ae",
        "Password": "ham87"
    },
    {
        "eSIS": 570265,
        "Name": "SAEED OMAR HASAN MOHAMED ALMENHALI",
        "Class": "G10-A",
        "Username": "s570265@charterschools.ae",
        "Password": "hyena95"
    },
    {
        "eSIS": 570493,
        "Name": "MOHAMED SAEED MOHAMED MUSALLAM ALMENHALI",
        "Class": "G10-D",
        "Username": "s570493@charterschools.ae",
        "Password": "ball92"
    },
    {
        "eSIS": 570554,
        "Name": "MSHARY OMAR MOHAMED SALEM ALBREIKI",
        "Class": "G10-F",
        "Username": "s570554@charterschools.ae",
        "Password": "pants86"
    },
    {
        "eSIS": 570558,
        "Name": "AWIDAN ABOOD SALEM MOHAMED ALMAHRI",
        "Class": "G10-F",
        "Username": "s570558@charterschools.ae",
        "Password": "oval94"
    },
    {
        "eSIS": 570561,
        "Name": "TAHNOON MOHAMED HUSAIN AHMED ALMESSABI",
        "Class": "G10-D",
        "Username": "s570561@charterschools.ae",
        "Password": "juice38"
    },
    {
        "eSIS": 570965,
        "Name": "SAIF SUHAIL ABDULBAKI",
        "Class": "G10-B",
        "Username": "s570965@charterschools.ae",
        "Password": "face43"
    },
    {
        "eSIS": 570986,
        "Name": "SULTAN AHMED SALEM ALMENHALI",
        "Class": "G10-E",
        "Username": "s570986@charterschools.ae",
        "Password": "galaxy83"
    },
    {
        "eSIS": 571016,
        "Name": "MOHAMMED YAZID AHMED BIN SAEED",
        "Class": "G10-G",
        "Username": "s571016@charterschools.ae",
        "Password": "skunk75"
    },
    {
        "eSIS": 571151,
        "Name": "ABDULLA SALEM ABDULLA SALEH ALMENHALI",
        "Class": "G10-A",
        "Username": "s571151@charterschools.ae",
        "Password": "taxi81"
    },
    {
        "eSIS": 571470,
        "Name": "HAMAD ABDULLAH MUBARAK BIN ABADAT",
        "Class": "G10-E",
        "Username": "s571470@charterschools.ae",
        "Password": "panther76"
    },
    {
        "eSIS": 571721,
        "Name": "KHATTAB FAHAID GHAZI FAHAID ALHARTHI",
        "Class": "G10-A",
        "Username": "s571721@charterschools.ae",
        "Password": "light77"
    },
    {
        "eSIS": 571746,
        "Name": "Tannaf Abdulla Bakheet Musabah Bakheet Al Menhali",
        "Class": "G10-A",
        "Username": "s571746@charterschools.ae",
        "Password": "dream23"
    },
    {
        "eSIS": 571767,
        "Name": "SULTAN SALEM OMAR ALI ALBREIKI",
        "Class": "G10-F",
        "Username": "s571767@charterschools.ae",
        "Password": "airplane94"
    },
    {
        "eSIS": 571806,
        "Name": "ABDULLA AWADH ABDULLA ALSUWAIDER ALWAHEDI",
        "Class": "G10-C",
        "Username": "s571806@charterschools.ae",
        "Password": "cougar69"
    },
    {
        "eSIS": 571848,
        "Name": "KHALIFA HAMAD ABUBAKER MOHAMED ALJAEEDI",
        "Class": "G10-E",
        "Username": "s571848@charterschools.ae",
        "Password": "ball72"
    },
    {
        "eSIS": 572073,
        "Name": "SULTAN AHMED SALEH OBAID ALAREEFI",
        "Class": "G10-D",
        "Username": "s572073@charterschools.ae",
        "Password": "candle89"
    },
    {
        "eSIS": 572109,
        "Name": "Khalid Saeed Hasan Jaryo Almnhali",
        "Class": "G10-G",
        "Username": "s572109@charterschools.ae",
        "Password": "tiger77"
    },
    {
        "eSIS": 572254,
        "Name": "Jaber Turki Hasan Saleh Al qahtani",
        "Class": "G10-A",
        "Username": "s572254@charterschools.ae",
        "Password": "hotdog33"
    },
    {
        "eSIS": 572270,
        "Name": "ABDULRAHMAN JABER ALABED ABOUD ALJABERI",
        "Class": "G10-G",
        "Username": "s572270@charterschools.ae",
        "Password": "spooky20"
    },
    {
        "eSIS": 572383,
        "Name": "FADHEL ANEES FADHEL MOHAMED ALYAFEI",
        "Class": "G10-G",
        "Username": "s572383@charterschools.ae",
        "Password": "pink42"
    },
    {
        "eSIS": 572415,
        "Name": "Saeed Hamdan Saeed ALI Al Menhali",
        "Class": "G10-A",
        "Username": "s572415@charterschools.ae",
        "Password": "panda40"
    },
    {
        "eSIS": 572496,
        "Name": "KHALED AHMED SHAIKH ALDAGHARI ALAWLAQI",
        "Class": "G10-F",
        "Username": "s572496@charterschools.ae",
        "Password": "pansy76"
    },
    {
        "eSIS": 575588,
        "Name": "KHALED ABDULRAHMAN SHAAR SALEH",
        "Class": "G10-F",
        "Username": "s575588@charterschools.ae",
        "Password": "yummy12"
    },
    {
        "eSIS": 576969,
        "Name": "ZAYED AMMAR KHAMEES SALEM AL KHANSOORI",
        "Class": "G10-C",
        "Username": "s576969@charterschools.ae",
        "Password": "seaweed11"
    },
    {
        "eSIS": 579130,
        "Name": "Abdullah Hasan Abdullah Al-Hamed",
        "Class": "G10-G",
        "Username": "s579130@charterschools.ae",
        "Password": "pig77"
    },
    {
        "eSIS": 579686,
        "Name": "HAZZA YAZID TALEB BIN TALEB",
        "Class": "G10-E",
        "Username": "s579686@charterschools.ae",
        "Password": "pencil86"
    },
    {
        "eSIS": 580851,
        "Name": "FARIS AHMED SALEH AL SHAIBANI",
        "Class": "G10-G",
        "Username": "s580851@charterschools.ae",
        "Password": "panther44"
    },
    {
        "eSIS": 581235,
        "Name": "ALI AHMED ALI KHALFAN ALHAMMADI",
        "Class": "G10-A",
        "Username": "s581235@charterschools.ae",
        "Password": "crab73"
    },
    {
        "eSIS": 582855,
        "Name": "ABDULLAH AHMED ABDULMOATI HAMMAD ALMAHRI",
        "Class": "G10-G",
        "Username": "s582855@charterschools.ae",
        "Password": "baseball94"
    },
    {
        "eSIS": 583689,
        "Name": "ali hasan ali ghuloum mousa alobeidli",
        "Class": "G10-H",
        "Username": "s583689@charterschools.ae",
        "Password": "light67"
    },
    {
        "eSIS": 584514,
        "Name": "SAQER KHALFAN JUMA RASHED ALRASHDI",
        "Class": "G10-D",
        "Username": "s584514@charterschools.ae",
        "Password": "yellow69"
    },
    {
        "eSIS": 584986,
        "Name": "NAHYAN MOHAMED MUSABBAH SAEED ALSHEBLI",
        "Class": "G10-A",
        "Username": "s584986@charterschools.ae",
        "Password": "bark77"
    },
    {
        "eSIS": 585505,
        "Name": "AHMED HAMAD SARHAN SAEED ALSHUAIBI",
        "Class": "G10-G",
        "Username": "s585505@charterschools.ae",
        "Password": "wonder28"
    },
    {
        "eSIS": 586379,
        "Name": "ALI MOHAMMED ALI MOHAMMED ALHAMRI",
        "Class": "G10-B",
        "Username": "s586379@charterschools.ae",
        "Password": "onion14"
    },
    {
        "eSIS": 586473,
        "Name": "Saleh Mabkhout Saleh Al Neaimi Altahesh Almenahli",
        "Class": "G10-E",
        "Username": "s586473@charterschools.ae",
        "Password": "peacock84"
    },
    {
        "eSIS": 587926,
        "Name": "NABEEL NAJI SAYED NASER HASHEM SHARAF NASER",
        "Class": "G10-C",
        "Username": "s587926@charterschools.ae",
        "Password": "coffee52"
    },
    {
        "eSIS": 589350,
        "Name": "AHMED SAEED RASHED SALEM ALGHFELI",
        "Class": "G10-G",
        "Username": "s589350@charterschools.ae",
        "Password": "stingray66"
    },
    {
        "eSIS": 591401,
        "Name": "OMAR ADEL ABDELRAHMAN MOSTAFA AHMED",
        "Class": "G10-D",
        "Username": "s591401@charterschools.ae",
        "Password": "slug63"
    },
    {
        "eSIS": 591784,
        "Name": "faisal mohamed rashid mohamed al zaabi",
        "Class": "G10-B",
        "Username": "s591784@charterschools.ae",
        "Password": "bat70"
    },
    {
        "eSIS": 594278,
        "Name": "Saif Ahmed Hamed Abdul Qader Alkatheeri",
        "Class": "G10-E",
        "Username": "s594278@charterschools.ae",
        "Password": "tall62"
    },
    {
        "eSIS": 594642,
        "Name": "SAEED AHMED SAEED BAKHEET ALRASHDI",
        "Class": "G10-G",
        "Username": "s594642@charterschools.ae",
        "Password": "yeti81"
    },
    {
        "eSIS": 595201,
        "Name": "MOHAMED RASHED NASSER MOHAMED ABDULHAY",
        "Class": "G10-H",
        "Username": "s595201@charterschools.ae",
        "Password": "camera66"
    },
    {
        "eSIS": 595297,
        "Name": "AHMED YOUSEF AHMED BUGHAITH ALHAMMADI",
        "Class": "G10-E",
        "Username": "s595297@charterschools.ae",
        "Password": "deer68"
    },
    {
        "eSIS": 595608,
        "Name": "HEMDAN SAOUD AHMED A ALMARZOOQI",
        "Class": "G10-D",
        "Username": "s595608@charterschools.ae",
        "Password": "bird84"
    },
    {
        "eSIS": 596634,
        "Name": "NASER YOUSSEF MOHAMAD AL HAMADI",
        "Class": "G10-B",
        "Username": "s596634@charterschools.ae",
        "Password": "duck15"
    },
    {
        "eSIS": 596917,
        "Name": "HAMAD MOHAMMED HADI AIDAS ALRASHDI",
        "Class": "G10-E",
        "Username": "s596917@charterschools.ae",
        "Password": "rooster61"
    },
    {
        "eSIS": 598018,
        "Name": "Butti Khamis Matar Al mekhaini Al Jneibe",
        "Class": "G10-B",
        "Username": "s598018@charterschools.ae",
        "Password": "pear77"
    },
    {
        "eSIS": 599477,
        "Name": "MATAR HAMAD MUHAYER HUMAID ALKTEBI",
        "Class": "G10-D",
        "Username": "s599477@charterschools.ae",
        "Password": "mars88"
    },
    {
        "eSIS": 600665,
        "Name": "RASHED MUSTAFA RAJAB SAEED ALMARRI",
        "Class": "G10-D",
        "Username": "s600665@charterschools.ae",
        "Password": "whistle40"
    },
    {
        "eSIS": 600668,
        "Name": "SALEH SALEM MOHAMED ALOMAR ALMENHALI",
        "Class": "G10-G",
        "Username": "s600668@charterschools.ae",
        "Password": "green85"
    },
    {
        "eSIS": 601712,
        "Name": "Zayed Khalid Abdulla Najem S Bu Hasan",
        "Class": "G10-A",
        "Username": "s601712@charterschools.ae",
        "Password": "crane38"
    },
    {
        "eSIS": 602098,
        "Name": "MAYED FARES ABDULLA ALSENAANI ALJNEIBI",
        "Class": "G10-E",
        "Username": "s602098@charterschools.ae",
        "Password": "beetle97"
    },
    {
        "eSIS": 602552,
        "Name": "HAREB KHALIFA HAREB SAEED ALKAABI",
        "Class": "G10-H",
        "Username": "s602552@charterschools.ae",
        "Password": "music96"
    },
    {
        "eSIS": 604326,
        "Name": "ZAYED SULTAN SALEM SALEEM ALAMERI",
        "Class": "G10-E",
        "Username": "s604326@charterschools.ae",
        "Password": "carp71"
    },
    {
        "eSIS": 604588,
        "Name": "ALI ABDULLA ALI MOHAMED ALBREIKI",
        "Class": "G10-D",
        "Username": "s604588@charterschools.ae",
        "Password": "koala53"
    },
    {
        "eSIS": 605052,
        "Name": "KHALED YASER ALI MOHAMED ALMAHRI",
        "Class": "G10-B",
        "Username": "s605052@charterschools.ae",
        "Password": "stamp93"
    },
    {
        "eSIS": 607043,
        "Name": "MOHSIN ABDULLA MOHSIN YAHYA ALSAEEDI",
        "Class": "G10-B",
        "Username": "s607043@charterschools.ae",
        "Password": "raccoon11"
    },
    {
        "eSIS": 607746,
        "Name": "MOHAMMED KHALFAN SALIM AL MAHROUQI",
        "Class": "G10-C",
        "Username": "s607746@charterschools.ae",
        "Password": "whistle18"
    },
    {
        "eSIS": 607829,
        "Name": "KHALED MOHAMED MOHSEN NASER ALNUAIMI",
        "Class": "G10-H",
        "Username": "s607829@charterschools.ae",
        "Password": "jazzy53"
    },
    {
        "eSIS": 610099,
        "Name": "ABDULLA MOHAMED SALEM MOHAMED ALAMERI",
        "Class": "G10-C",
        "Username": "s610099@charterschools.ae",
        "Password": "hide58"
    },
    {
        "eSIS": 612535,
        "Name": "MAGED ALI HUSSEIN",
        "Class": "G10-H",
        "Username": "s612535@charterschools.ae",
        "Password": "cereal49"
    },
    {
        "eSIS": 613304,
        "Name": "HASHEM OMAR ABDULQADER ABOBAKR ALAYDAROOS",
        "Class": "G10-B",
        "Username": "s613304@charterschools.ae",
        "Password": "agent66"
    },
    {
        "eSIS": 613759,
        "Name": "ABDULLA NABIL MOHAMED SAEED ALAMERI",
        "Class": "G10-F",
        "Username": "s613759@charterschools.ae",
        "Password": "brown54"
    },
    {
        "eSIS": 617048,
        "Name": "SAEED AHMED MOHSEN NASSER ALAMERI",
        "Class": "G10-F",
        "Username": "s617048@charterschools.ae",
        "Password": "earth34"
    },
    {
        "eSIS": 617202,
        "Name": "SAIF MUBARAK MOHAMED ALMAAROOFI ALHARTHI",
        "Class": "G10-E",
        "Username": "s617202@charterschools.ae",
        "Password": "lizard99"
    },
    {
        "eSIS": 617865,
        "Name": "NASER IBRAHIM SALEH ABEDRABOH ALNUAIMI",
        "Class": "G10-E",
        "Username": "s617865@charterschools.ae",
        "Password": "fluffy59"
    },
    {
        "eSIS": 619653,
        "Name": "MOHAMED MUBARAK MOHAMED SAEED ALRASHDI",
        "Class": "G10-F",
        "Username": "s619653@charterschools.ae",
        "Password": "kiwi24"
    },
    {
        "eSIS": 620308,
        "Name": "SULTAN ABDALLAH SULTAN ALRASHDI",
        "Class": "G10-E",
        "Username": "s620308@charterschools.ae",
        "Password": "reindeer42"
    },
    {
        "eSIS": 620441,
        "Name": "Saeed Khuwaitem Al Rashidi",
        "Class": "G10-G",
        "Username": "s620441@charterschools.ae",
        "Password": "tree67"
    },
    {
        "eSIS": 621203,
        "Name": "HAMDAN SAEED MOHAMED GHAMMADH ALRASHDI",
        "Class": "G10-H",
        "Username": "s621203@charterschools.ae",
        "Password": "monkey88"
    },
    {
        "eSIS": 625935,
        "Name": "MOHAMEED YOUSEF IBRAHIM ABDULLA ALHOSANI",
        "Class": "G10-G",
        "Username": "s625935@charterschools.ae",
        "Password": "ski78"
    },
    {
        "eSIS": 626887,
        "Name": "YOUSEF MOHAMED AHMED MAHDI ALKATHEERI",
        "Class": "G10-E",
        "Username": "s626887@charterschools.ae",
        "Password": "clock75"
    },
    {
        "eSIS": 629170,
        "Name": "FAHD ALI HUSSEIN HATROSH",
        "Class": "G10-D",
        "Username": "s629170@charterschools.ae",
        "Password": "farm77"
    },
    {
        "eSIS": 630011,
        "Name": "SAIF JABER SAEED AHWARERA ALMUHARRAMI",
        "Class": "G10-H",
        "Username": "s630011@charterschools.ae",
        "Password": "teapot15"
    },
    {
        "eSIS": 630047,
        "Name": "GHAITH JASEM MOHAMED BAQER ALHOSANI",
        "Class": "G10-H",
        "Username": "s630047@charterschools.ae",
        "Password": "sesame37"
    },
    {
        "eSIS": 630150,
        "Name": "Musallam Hamad Bakheet Kedairi Almansoori",
        "Class": "G10-H",
        "Username": "s630150@charterschools.ae",
        "Password": "hyena13"
    },
    {
        "eSIS": 631297,
        "Name": "FAISAL OMAR ABUBAKER SHAIKHOMAR ALAMOODI",
        "Class": "G10-H",
        "Username": "s631297@charterschools.ae",
        "Password": "tea40"
    },
    {
        "eSIS": 631848,
        "Name": "NASER EISSA NASER HUSAIN ALNUAIMI",
        "Class": "G10-A",
        "Username": "s631848@charterschools.ae",
        "Password": "biking31"
    },
    {
        "eSIS": 631867,
        "Name": "TAMIM EISSA NASER HUSAIN ALNUAIMI",
        "Class": "G10-A",
        "Username": "s631867@charterschools.ae",
        "Password": "key48"
    },
    {
        "eSIS": 631884,
        "Name": "MOHAMED TALAL MOHAMED ALSHASHAEI ALKATHEERI",
        "Class": "G10-H",
        "Username": "s631884@charterschools.ae",
        "Password": "butter95"
    },
    {
        "eSIS": 632169,
        "Name": "SALEM MOHSIN MOHAMMED ALRASHDI",
        "Class": "G10-D",
        "Username": "s632169@charterschools.ae",
        "Password": "clown92"
    },
    {
        "eSIS": 632291,
        "Name": "SALIM AHMED MOHAMMED ALZUWAIDI",
        "Class": "G10-F",
        "Username": "s632291@charterschools.ae",
        "Password": "book52"
    },
    {
        "eSIS": 632292,
        "Name": "AHMED ARIF MUSLEM BAIT ALSHARI KHAWAR",
        "Class": "G10-B",
        "Username": "s632292@charterschools.ae",
        "Password": "salt86"
    },
    {
        "eSIS": 632333,
        "Name": "SAAD MOHAMED SAAD OBAID ALMAHRI",
        "Class": "G10-C",
        "Username": "s632333@charterschools.ae",
        "Password": "onion80"
    },
    {
        "eSIS": 632796,
        "Name": "MOHAMED SALEH AHMED SALEH AL-ALI",
        "Class": "G10-B",
        "Username": "s632796@charterschools.ae",
        "Password": "leek96"
    },
    {
        "eSIS": 634604,
        "Name": "Mubarak Madhi Omar Areed Al Mansoori",
        "Class": "G10-C",
        "Username": "s634604@charterschools.ae",
        "Password": "crab81"
    },
    {
        "eSIS": 635676,
        "Name": "naji mohamed mabkhoot mubarak nasser alobaidi",
        "Class": "G10-F",
        "Username": "s635676@charterschools.ae",
        "Password": "mole17"
    },
    {
        "eSIS": 635707,
        "Name": "BAKHEET SALEM ABDULLA KAMAAS ALMENHALI",
        "Class": "G10-B",
        "Username": "s635707@charterschools.ae",
        "Password": "red52"
    },
    {
        "eSIS": 635780,
        "Name": "Saeed Hasan Mabkhout Mohamed Alameri",
        "Class": "G10-A",
        "Username": "s635780@charterschools.ae",
        "Password": "fun44"
    },
    {
        "eSIS": 635983,
        "Name": "saud saleh awn askar alseiari",
        "Class": "G10-A",
        "Username": "s635983@charterschools.ae",
        "Password": "burrito46"
    },
    {
        "eSIS": 636177,
        "Name": "Rashed Saeed Omar Saeed Al Ameri",
        "Class": "G10-D",
        "Username": "s636177@charterschools.ae",
        "Password": "grape38"
    },
    {
        "eSIS": 636424,
        "Name": "SALEH SALEM SALEH SALEEM ALMENHALI",
        "Class": "G10-G",
        "Username": "s636424@charterschools.ae",
        "Password": "umbrella88"
    },
    {
        "eSIS": 636987,
        "Name": "Sultan Hassan Yousif Hasan Alyahri",
        "Class": "G10-C",
        "Username": "s636987@charterschools.ae",
        "Password": "marble69"
    },
    {
        "eSIS": 637510,
        "Name": "ZAYED BADER SALEH OBAID AL AREEFI",
        "Class": "G10-E",
        "Username": "s637510@charterschools.ae",
        "Password": "rice40"
    },
    {
        "eSIS": 637589,
        "Name": "HUSSEIN BADER JAFER BIN AQEEL",
        "Class": "G10-H",
        "Username": "s637589@charterschools.ae",
        "Password": "salsa14"
    },
    {
        "eSIS": 637762,
        "Name": "KHALIFA AMER KHALIFA JUMA ALKAABI",
        "Class": "G10-E",
        "Username": "s637762@charterschools.ae",
        "Password": "tuna29"
    },
    {
        "eSIS": 638924,
        "Name": "MOHAMED SALEH MUBARAK MABKHOOT ALRASHDI",
        "Class": "G10-B",
        "Username": "s638924@charterschools.ae",
        "Password": "button76"
    },
    {
        "eSIS": 640482,
        "Name": "Saif Eisa Naser Husain Abdulla Alnuaimi",
        "Class": "G10-H",
        "Username": "s640482@charterschools.ae",
        "Password": "school17"
    },
    {
        "eSIS": 640776,
        "Name": "HAMDAN AHMED ABDULLAH AL NAKHEAI",
        "Class": "G10-E",
        "Username": "s640776@charterschools.ae",
        "Password": "clock46"
    },
    {
        "eSIS": 641111,
        "Name": "Mohammed Nasser Saleh Ali",
        "Class": "G10-A",
        "Username": "s641111@charterschools.ae",
        "Password": "short27"
    },
    {
        "eSIS": 641464,
        "Name": "ZAYED KHALED ALI ABDULHABEB",
        "Class": "G10-F",
        "Username": "s641464@charterschools.ae",
        "Password": "owl61"
    },
    {
        "eSIS": 642918,
        "Name": "SAEED SALEM MOHAMED AQEEL ALMENHALI",
        "Class": "G10-F",
        "Username": "s642918@charterschools.ae",
        "Password": "ferret60"
    },
    {
        "eSIS": 644056,
        "Name": "NASSER ALI NASSER AL MAMARI",
        "Class": "G10-B",
        "Username": "s644056@charterschools.ae",
        "Password": "toucan94"
    },
    {
        "eSIS": 647507,
        "Name": "HAMAD AMER SAEED MOHAMED ALMUHARRAMI",
        "Class": "G10-D",
        "Username": "s647507@charterschools.ae",
        "Password": "coyote21"
    },
    {
        "eSIS": 653079,
        "Name": "SAEED MOHAMMED THABIT GUNDB",
        "Class": "G10-F",
        "Username": "s653079@charterschools.ae",
        "Password": "cone20"
    },
    {
        "eSIS": 655182,
        "Name": "ABDULLAH FAHAD MOHSEN SHEHAB",
        "Class": "G10-H",
        "Username": "s655182@charterschools.ae",
        "Password": "grape75"
    },
    {
        "eSIS": 655404,
        "Name": "MANSOUR YOUSEF GHOLAMI",
        "Class": "G10-B",
        "Username": "s655404@charterschools.ae",
        "Password": "bat99"
    },
    {
        "eSIS": 655665,
        "Name": "ABDULLAH ALI ABDULLAH AL TAHERI",
        "Class": "G10-G",
        "Username": "s655665@charterschools.ae",
        "Password": "glitter38"
    },
    {
        "eSIS": 655809,
        "Name": "MUBARAK SAEED MUSALLAM HASAN ALRASHDI",
        "Class": "G10-B",
        "Username": "s655809@charterschools.ae",
        "Password": "galaxy92"
    },
    {
        "eSIS": 657007,
        "Name": "Mohamed Fayez Tamatem Harbi Almenhali",
        "Class": "G10-H",
        "Username": "s657007@charterschools.ae",
        "Password": "space54"
    },
    {
        "eSIS": 657260,
        "Name": "ALI SADEQ ALI LAHWAL",
        "Class": "G10-A",
        "Username": "s657260@charterschools.ae",
        "Password": "button84"
    },
    {
        "eSIS": 657392,
        "Name": "ZAYED MOHAMMED ALI NASER ALNUAIMI",
        "Class": "G10-D",
        "Username": "s657392@charterschools.ae",
        "Password": "brain76"
    },
    {
        "eSIS": 657854,
        "Name": "SALEM JUMAA MOHMED MUSALLAM ALMENHALI",
        "Class": "G10-H",
        "Username": "s657854@charterschools.ae",
        "Password": "boar53"
    },
    {
        "eSIS": 658194,
        "Name": "GHAITH NAJI SALEH HADI ALHARTHI",
        "Class": "G10-H",
        "Username": "s658194@charterschools.ae",
        "Password": "melon47"
    },
    {
        "eSIS": 658266,
        "Name": "ALWALEED ALI HUSSEIN BAKER",
        "Class": "G10-H",
        "Username": "s658266@charterschools.ae",
        "Password": "peacock66"
    },
    {
        "eSIS": 658647,
        "Name": "AHMED YASER AHMED MOHAMMED AL SALAHI",
        "Class": "G10-A",
        "Username": "s658647@charterschools.ae",
        "Password": "wolf55"
    },
    {
        "eSIS": 659707,
        "Name": "HAMAD QASEM ABDULHAFEDH QASEM",
        "Class": "G10-G",
        "Username": "s659707@charterschools.ae",
        "Password": "spooky38"
    },
    {
        "eSIS": 660544,
        "Name": "ABDULLA SABRI YOUSEF ALYAHRI ALYAFEI",
        "Class": "G10-C",
        "Username": "s660544@charterschools.ae",
        "Password": "golf80"
    },
    {
        "eSIS": 662921,
        "Name": "HAMDAN OMAR AHMED AL HEMYARI",
        "Class": "G10-B",
        "Username": "s662921@charterschools.ae",
        "Password": "electric23"
    },
    {
        "eSIS": 669345,
        "Name": "MOHAMED ALI HADI AIDAS ALRASHDI",
        "Class": "G10-F",
        "Username": "s669345@charterschools.ae",
        "Password": "snack23"
    },
    {
        "eSIS": 681654,
        "Name": "ZAYED MOHAMED ALI TALIB ALHARTHI",
        "Class": "G10-B",
        "Username": "s681654@charterschools.ae",
        "Password": "jump33"
    },
    {
        "eSIS": 682851,
        "Name": "EBRAHIM ANWAR AWADH ALKATHEIRI",
        "Class": "G10-A",
        "Username": "s682851@charterschools.ae",
        "Password": "jelly67"
    },
    {
        "eSIS": 684549,
        "Name": "IBRAHIM MOHAMMED A ALMAHRI",
        "Class": "G10-G",
        "Username": "s684549@charterschools.ae",
        "Password": "finger77"
    },
    {
        "eSIS": 691678,
        "Name": "SUHAIL HAMAD MOHAMED ALHUSAIN ALMENHALI",
        "Class": "G10-F",
        "Username": "s691678@charterschools.ae",
        "Password": "rock16"
    },
    {
        "eSIS": 703032,
        "Name": "AMMAR KHALED MOHSEN SAQQAF AHMED",
        "Class": "G10-B",
        "Username": "s703032@charterschools.ae",
        "Password": "camel83"
    },
    {
        "eSIS": 710682,
        "Name": "Mohammed Saeed Hareb Saeed Alkaabi",
        "Class": "G10-E",
        "Username": "s710682@charterschools.ae",
        "Password": "honey85"
    },
    {
        "eSIS": 712560,
        "Name": "MANSOOR ABDALLAH NASSER MANSOOR AL HASANI",
        "Class": "G10-H",
        "Username": "s712560@charterschools.ae",
        "Password": "squid80"
    },
    {
        "eSIS": 713093,
        "Name": "SULTAN ALI ABDULLA MUBARAK ALMANSOORI",
        "Class": "G10-C",
        "Username": "s713093@charterschools.ae",
        "Password": "king33"
    },
    {
        "eSIS": 713557,
        "Name": "NASSER MOHAMMED NASSER AL MASHGARI",
        "Class": "G10-G",
        "Username": "s713557@charterschools.ae",
        "Password": "duck97"
    },
    {
        "eSIS": 713702,
        "Name": "bakheet ahmed bakheet tannaf almenhali",
        "Class": "G10-D",
        "Username": "s713702@charterschools.ae",
        "Password": "coffee81"
    },
    {
        "eSIS": 714025,
        "Name": "HAMAD HAZZAA HAMAD HAMED ALMENHALI",
        "Class": "G10-F",
        "Username": "s714025@charterschools.ae",
        "Password": "sneakers98"
    },
    {
        "eSIS": 714536,
        "Name": "AHMED NASSER SALEH HUSAIN ALHASHMI",
        "Class": "G10-F",
        "Username": "s714536@charterschools.ae",
        "Password": "toucan44"
    },
    {
        "eSIS": 715720,
        "Name": "Mohammed Abdulwahed A Alharthi",
        "Class": "G10-A",
        "Username": "s715720@charterschools.ae",
        "Password": "peacock94"
    },
    {
        "eSIS": 720394,
        "Name": "KHALED HASAN ALAID AL SWIDAN",
        "Class": "G10-D",
        "Username": "s720394@charterschools.ae",
        "Password": "papaya23"
    },
    {
        "eSIS": 729485,
        "Name": "ABDULLAH YAHYA MOHAMMED ABDULLAH",
        "Class": "G10-H",
        "Username": "s729485@charterschools.ae",
        "Password": "melon58"
    },
    {
        "eSIS": 777621,
        "Name": "Mahmoud Hesham Mahmoud Mahmoud Mousa Abd Elrahman",
        "Class": "G10-F",
        "Username": "s777621@charterschools.ae",
        "Password": "maple31"
    },
    {
        "eSIS": 821495,
        "Name": "ZAYED IBRAHIM SHAIKHAN BADER ALMENTHERI",
        "Class": "G10-B",
        "Username": "s821495@charterschools.ae",
        "Password": "melon48"
    },
    {
        "eSIS": 869225,
        "Name": "Mohamed Saeed Huwaidi Salem Almuharrami",
        "Class": "G10-H",
        "Username": "s869225@charterschools.ae",
        "Password": "cobra53"
    },
    {
        "eSIS": 715173,
        "Name": "Saeed Ali Saeed Abdulla Alameri",
        "Class": "G10-A",
        "Username": NaN,
        "Password": NaN
    },
    {
        "eSIS": 586310,
        "Name": "MOHAMMED SALEM YOUSUF JUMA ALHOSANI",
        "Class": "G10-B",
        "Username": NaN,
        "Password": NaN
    },
    {
        "eSIS": 629843,
        "Name": "Abdulaziz Saeed Hasan Ali Almuharrami",
        "Class": "G10-C",
        "Username": NaN,
        "Password": NaN
    },
    {
        "eSIS": 564329,
        "Name": "SULLTAN KARAMA AWADH SALEM ALKATHEERI",
        "Class": "G10-C",
        "Username": NaN,
        "Password": NaN
    },
    {
        "eSIS": 597815,
        "Name": "RAYES BURAYS SALEM ALMENHALI",
        "Class": "G10-D",
        "Username": NaN,
        "Password": NaN
    },
    {
        "eSIS": 719611,
        "Name": "Abdulla Ali Naji Salem Husain Al Harthi",
        "Class": "G10-G",
        "Username": NaN,
        "Password": NaN
    },
    {
        "eSIS": 567901,
        "Name": "ABDULLA MOHAMED ABDULLA BAKHEET ALMENHALI",
        "Class": "G10-G",
        "Username": NaN,
        "Password": NaN
    },
    {
        "eSIS": 646100,
        "Name": "MOHAMED MUSALLAM MOHAMED AQEEL ALMENHALI",
        "Class": "G10-G",
        "Username": NaN,
        "Password": NaN
    },
    {
        "eSIS": 586381,
        "Name": "SALEM SALEH S ALMINHALI",
        "Class": "G10-H",
        "Username": NaN,
        "Password": NaN
    },
    {
        "eSIS": 655101,
        "Name": "ABDULLAH AHMED ABDULLAH AL HADDAD",
        "Class": "G10-H",
        "Username": "ahaddad31",
        "Password": "dance92"
    }
];
export default amplify_usersData;

