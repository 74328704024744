import React, { useState } from "react";
import "./IntSessions.css";

const IntSessions = () => {
  const [isSessionStarted, setIsSessionStarted] = useState(false);

  const handleSessionToggle = () => {
    setIsSessionStarted((prevState) => !prevState);
  };

  const sessions = [
    {
      id: 1,
      day: "Monday",
      date: "10 May",
    },
    {
      id: 2,
      day: "Tuesday",
      date: "11 May",
    },
    {
      id: 3,
      day: "Wednesday",
      date: "12 May",
    },
  ];

  return (
    <div className="sessions-container">
      <div className="session-toggle">
        <button onClick={handleSessionToggle}>
          {isSessionStarted ? "End Session" : "Start Session"}
        </button>
      </div>
      <div className="session-list">
        {sessions.map((session) => (
          <div key={session.id} className="session-item">
            <div className="session-info">
              <h3>Session {session.id}</h3>
              <p>{session.day}, {session.date}</p>
            </div>
            <div className="session-actions">
              <button>Edit</button>
              <button>View</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IntSessions;
