import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import QuizIcon from "@mui/icons-material/Quiz";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import AssessmentIcon from "@mui/icons-material/Assessment";
import GroupsIcon from "@mui/icons-material/Groups";
import WaterfallChartIcon from "@mui/icons-material/WaterfallChart";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import TeacherMapReport from "./TeacherMapReport";
import AttendanceTeacher from "../views/AttendanceTeacher";
import Attendancehistory from "./AttendanceHistory";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import CoverageNew from "../CoverageNew";
import AssessmentManager from "./AssessmentManager";
import PsychologyIcon from "@mui/icons-material/Psychology";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import AttendanceTakingTracker from "./AttendanceTakingTracker";
import PlaylistAddCheckCircleIcon from "@mui/icons-material/PlaylistAddCheckCircle";
import ProgressReport from "./ProgressReport";
import LogoutIcon from "@mui/icons-material/Logout";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
// import SuperadminFinalChecker from "./SuperadminFinalChecker";
import SummarizeIcon from "@mui/icons-material/Summarize";
import EndoFTermReport from "./EndoFTermReport";
import ClassLists from "./classLists/ClassLists";
import Exams from "./Exams";
// import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import GradeIcon from "@mui/icons-material/Grade";
import StandardsData from "../components/exams/StandardsData";
import StudentsPerformance from "../components/exams/StudentsPerformance";
import Ccss from "../components/curriculum/Ccss";
import AbcIcon from "@mui/icons-material/Abc";
import IntNavigationBar from "../components/intervention/IntNavigationBar";
// import StandardsUploader from "../components/exams/StandardsUploader";
import SuperadminEndOfYear from "./SuperadminEndOfYear";
import MapAnalysis from "../map/MapAnalysis";
// import TeacherDashboard from "../map/TeacherDashboard";
import TimetableNew from "./timetableNew";
import CreditHoursTracker from "../CreditHoursTracker";
import PunchClockIcon from '@mui/icons-material/PunchClock';
import WarningIcon from '@mui/icons-material/Warning';
import InterventionDashboard from "../InterventionAfterSchool/InterventionDashboard ";
import StudentsAtRisk from "../StudentsAtRisk/StudentsAtRisk";
// import AttendanceTeacherSummer from "./AttendanceTeacherSummer";
const TeacherNew = () => {
  const [state, setState] = useState({
    left: false,
  });
  // const [switcher, setSwitcher] = useState("mapAnalysis");
  const [switcher, setSwitcher] = useState("atrisk");
  // const [switcher, setSwitcher] = useState("Schedule Assessments");

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <Box
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {[
          "MAP Analysis",
          "Intervention الدعم",
          "Data Analysis تحليل البيانات",
          "Attendance الحضور و الغياب",
          "Individuals Assess. Data",
          "MAP Reports",
          "Students List قوائم الطلاب",
          "Marks Entry ادخال الدرجات",
          "End of Term Report",
          "AttendanceTakingTracker",
          "Progress Report",
          "Timetable",
          "Attendance History سجلات الحضور",
          "Marks Entry Tracker",
          "Assessments Scheduling",
          "creditHours",
          "Students at Risk",
          "Sign out",
        ].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton
              onClick={() => {
                if (index === 0) {
                  setSwitcher("mapAnalysis");
                }
                if (index === 1) {
                  setSwitcher("intervention");
                }
                if (index === 2) {
                  setSwitcher("Data Analysis");
                }
                if (index === 3) {
                  setSwitcher("attendance");
                }
                if (index === 4) {
                  setSwitcher("standardsData");
                }
                if (index === 5) {
                  setSwitcher("map");
                }
                if (index === 6) {
                  setSwitcher("lists");
                }
                if (index === 7) {
                  setSwitcher("manageAssessment");
                }
                if (index === 8) {
                  setSwitcher("endOftermReport");
                }
                if (index === 9) {
                  setSwitcher("AttendanceTakingTracker");
                }
                if (index === 10) {
                  setSwitcher("ProgressReport");
                }
                if (index === 11) {
                  setSwitcher("timetable");
                }
                if (index === 12) {
                  setSwitcher("attHistory");
                }
                if (index === 13) {
                  setSwitcher("MarksEntryTracker");
                }
                if (index === 14) {
                  setSwitcher("Schedule Assessments");
                }
                if (index === 15) {
                  setSwitcher("creditHours");
                }
                if (index === 16) {
                  setSwitcher("atrisk");
               
                }
                if (index === 17) {
                  sessionStorage.removeItem("user");
                  window.location.reload();
                }
              }}
            >
              <ListItemIcon>
                {index === 1 ? (
                  <AbcIcon style={{ color: "darkblue" }} />
                ) : index === 2 ? (
                  <WaterfallChartIcon style={{ color: "" }} />
                ) : index === 3 ? (
                  <GroupsIcon style={{ color: "darkblue" }} />
                ) : index === 4 ? (
                  <GradeIcon style={{ color: "darkblue" }} />
                ) : index === 5 ? (
                  <AssessmentIcon style={{ color: "darkblue" }} />
                ) : index === 6 ? (
                  <FormatListNumberedIcon style={{ color: "darkblue" }} />
                ) : index === 7 ? (
                  // ) :index === 6 && sessionStorage.getItem("trole") === "hd" ? (
                  <QuizIcon style={{ color: "darkblue" }} />
                ) : index === 8 ? (
                  <SummarizeIcon style={{ color: "darkblue" }} />
                ) : index === 9 ? (
                  // ) :index === 6 && sessionStorage.getItem("trole") === "hd" ? (
                  <GpsFixedIcon style={{ color: "darkblue" }} />
                ) : index === 10 ? (
                  <ShowChartIcon style={{ color: "darkblue" }} />
                ) : index === 11 ? (
                  <CalendarViewMonthIcon style={{ color: "darkblue" }} />
                ) : index === 12 ? (
                  <HistoryEduIcon style={{ color: "darkblue" }} />
                ) : index === 13 ? (
                  <PlaylistAddCheckCircleIcon style={{ color: "darkblue" }} />
                ) : index === 14 ? (
                  <AccessAlarmIcon style={{ color: "darkblue" }} />
                ) 
                : index === 16 ? (
                  <WarningIcon style={{ color: "darkblue" }} />
                ) 
                : index === 17 ? (
                  <LogoutIcon style={{ color: "darkblue" }} />
                ) 
                : index === 0 ? (
                  <PsychologyIcon style={{ color: "red", fontSize: "50px" }} />
                ) : index === 15 ? (
                  <PunchClockIcon style={{ color: "darkblue" }} />
                ) : (
                  ""
                )}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"left"}>
        <MenuIcon onClick={toggleDrawer("left", true)} fontSize="large" />
        <Drawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
        >
          {list("left")}
        </Drawer>
      </React.Fragment>
      <div>
        <div className="container">
          {switcher === "timetable" ? (
            <TimetableNew />
          ) : switcher === "map" ? (
            <TeacherMapReport />
          ) : switcher === "standardsData" ? (
            <StandardsData />
          ) : // <StandardsUploader/>
          switcher === "attendance" ? (
            <AttendanceTeacher />
          ) : // <AttendanceTeacherSummer />
          switcher === "attHistory" ? (
            <Attendancehistory />
          ) : switcher === "lists" ? (
            <ClassLists />
          ) : switcher === "coverage" ? (
            <CoverageNew />
          ) : switcher === "manageAssessment" ? (
            <AssessmentManager />
          ) : switcher === "AttendanceTakingTracker" ? (
            <AttendanceTakingTracker />
          ) : // <AttendanceTakingTrackerSummer />
          switcher === "ProgressReport" ? (
            <ProgressReport />
          ) : switcher === "MarksEntryTracker" ? (
            // <SuperadminFinalChecker />
            <SuperadminEndOfYear />
          ) : switcher === "endOftermReport" ? (
            <EndoFTermReport />
          ) : switcher === "Schedule Assessments" ? (
            <div>
              <Exams />
              {/* Comming soon..... */}
            </div>
          ) : switcher === "Data Analysis" ? (
            <div>
              <StudentsPerformance />
            </div>
          ) : switcher === "curriculum" ? (
            <div>
              <Ccss />
            </div>
          ) : switcher === "intervention" ? (
            <div>
              {/* <IntNavigationBar />
               */}
               {/* <TeacherDashboard/> */}
               <InterventionDashboard/>
            </div>
          ) : switcher === "mapAnalysis" ? (
            <div>
              <MapAnalysis />
             
              {/* <TeacherDashboard /> */}
            </div>
          ) 
          : switcher === "creditHours" ? (
            <div>
              <CreditHoursTracker />
              {/* <TeacherDashboard /> */}
            </div>
          )
          : switcher === "atrisk" ? (
            <div>
              <StudentsAtRisk />
              {/* <TeacherDashboard /> */}
            </div>
          )
           : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default TeacherNew;
