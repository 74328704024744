const teachersNames = [
  "Abdelraheem Kitana",
  "Alaa Mohammed",
  "Jelena Prepic",
  "Manal Naser",
  "Hamdy Elmasry",
  "Tamer Sakr",
  "Halim Rezk",
  "Hasan Shihan",
  "Ahmed Fawzy",
  "AbdulRahman Alsyouf",
  "Mohammed Surwar",
  "Alaa Osama",
  "Hazem Klouz",
  "Mohammad Khreis",
  "Basem Aksh",
  "Ahmad Kilani",
  "Rania Metwally",
  "Philip Smith",
  "Rami Rababah",
  "Mohamed Tarig",
  "Salman Hailat",
  "Hanan Salloum",
  "Samer Dabous",
  "Mohamed Elbayyar",
  "Ajith Sasikumar",
  "Sashnee Peter",
  "Antoine Ghanem",
  "Muhammad Akhtar",
  "Yousef Serrieh",
  "Nedal Ismail",
  "Mousab Althunaibat",
  "Hazem Elnaggar",
  "Nadia Mustafa",
  "Benyamin Alali",
  "Mahmoud Hussein",
  "Hassan Almasri",
  "Tamer Zahran",
  "Ahmed Attia",
  "Khadeejeh Hussein",
  "Othman Othman",
  "Hamad Shekaili",
  "Wael Radwan",
  "Salwa Khalifa",
  "Fayrouz Sabry",
  "Reem Talal",
].sort((a, b) => (a > b ? 1 : -1));
export default teachersNames;
