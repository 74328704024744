import React from "react";
// import classes from "./timetableData/classes";
import lightColorsRGB from "./timetableData/colors";

const TimetableTeacher = ({
  teachersObject,
  sectionObjects,
  classes,
  teacherplanningrooms,
}) => {
  const periods = ["p1", "p2", "p3", "p4", "p5", "p6"];

  function rgbStringToArray(rgbString) {
    // Remove the 'rgb(' prefix and ')' suffix to get only the numbers
    const rgbValues = rgbString ? rgbString.slice(4, -1) : "";
    // Split the values by ',' to get an array of strings
    const rgbArray = rgbValues
      .split(",")
      .map((value) => parseInt(value.trim(), 10));
    if (rgbString) {
      return rgbArray;
    } else {
      return [234, 221, 202]; // Default background color as array
    }
  }

  function getFontColorForBackground(background) {
    const [R, G, B] = background;
    const relativeLuminance = (0.2126 * R + 0.7152 * G + 0.0722 * B) / 255;

    // Default font color based on luminance
    let fontColor = relativeLuminance > 0.5 ? "black" : "white";

    // Ensure not both background and font color are white
    if (
      fontColor === "white" &&
      R === 255 &&
      G === 255 &&
      B === 255
    ) {
      fontColor = "black"; // Change font color to black if background is white
    }

    return fontColor;
  }

  return (
    <div>
      <div>
        <table className="schedule-table">
          <tbody>
            <tr>
              <th></th>
              <th className="timetableHeader">Homeroom</th>
              {periods?.map((p, pIndex) => {
                return <th key={pIndex + 10}>{p}</th>;
              })}
            </tr>
            {["m", "t", "w", "th", "f"].map((rowData, rowIndex) => (
              <tr key={rowIndex}>
                <th className="timetableHeader">
                  {
                    ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"][
                      ["m", "t", "w", "th", "f"].indexOf(rowData)
                    ]
                  }
                </th>
                <td>
                  <p>
                    {sectionObjects.filter(
                      (s) => s.homeroomteacher === teachersObject.teacher
                    ).length === 0
                      ? ""
                      : sectionObjects.filter(
                          (s) => s.homeroomteacher === teachersObject.teacher
                        )[0].section}
                  </p>
                  <p>
                    {sectionObjects.filter(
                      (s) => s.homeroomteacher === teachersObject.teacher
                    ).length !== 0
                      ? sectionObjects.filter(
                          (s) => s.homeroomteacher === teachersObject.teacher
                        )[0].homeroomClass
                      : ""}
                  </p>
                </td>

                {periods.map((p, pIndex) => {
                  const backgroundColor =
                    teachersObject[rowData][p] &&
                    teachersObject[rowData][p] !== "null"
                      ? lightColorsRGB[
                          classes.indexOf(teachersObject[rowData][p])
                        ]
                      : "";
                  
                  // Parse background color
                  const backgroundArray = rgbStringToArray(backgroundColor);
                  
                  let fontColor =
                    teachersObject[rowData][p] &&
                    teachersObject[rowData][p] !== "null" &&
                    teachersObject[rowData][p] !== "Planning" &&
                    teachersObject[rowData][p] !== "SIP"
                      ? getFontColorForBackground(backgroundArray)
                      : "red";

                  // Check if either background or font color are white
                  if (
                    (backgroundArray[0] === 255 && backgroundArray[1] === 255 && backgroundArray[2] === 255) && fontColor === "white"
                  ) {
                    fontColor = "black"; // Ensure font is not white if background is white
                  }

                  return (
                    <td
                      key={pIndex}
                      style={{
                        backgroundColor: backgroundColor,
                        color: fontColor,
                      }}
                    >
                      <div className="cell-content">
                        {teachersObject[rowData][p] !== "null" ? (
                          <>
                            {teachersObject[rowData][p] !== "Planning" ? (
                              <div className="">
                                <div>{teachersObject[rowData][p]}</div>
                                <div>
                                  {sectionObjects.filter(
                                    (s) =>
                                      s.section === teachersObject[rowData][p]
                                  )[0]
                                    ? sectionObjects.filter(
                                        (s) =>
                                          s.section ===
                                          teachersObject[rowData][p]
                                      )[0][rowData + "Sub"][p]
                                    : ""}
                                </div>
                                <div>
                                  {sectionObjects.filter(
                                    (s) =>
                                      s.section === teachersObject[rowData][p]
                                  )[0]
                                    ? sectionObjects.filter(
                                        (s) =>
                                          s.section ===
                                          teachersObject[rowData][p]
                                      )[0][rowData + "Rooms"][p]
                                    : ""}
                                </div>
                              </div>
                            ) : (
                              <div>
                                <strong>{teachersObject[rowData][p]}</strong>
                                <div></div>
                                <div>
                                  {
                                    teacherplanningrooms.filter(
                                      (t) =>
                                        t.teacher === teachersObject.teacher
                                    )[0][rowData + p.slice(-1)]
                                  }
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div>
        <br></br>
        <table className="schedule-table">
          <tbody></tbody>
        </table>
      </div>
    </div>
  );
};

export default TimetableTeacher;
