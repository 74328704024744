import React from "react";
const TimetableSection = ({ sectionData }) => {
  function generateColorCode(text) {
    // Calculate a unique number based on the text
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
      hash = text.charCodeAt(i) + ((hash << 5) - hash);
    }
    // Convert the number to RGB components
    // const r = Math.floor(text.length*256/10)
    // const g = Math.floor(text.length*598/20)
    // const b = Math.floor(text.length*300/5)
    const lighterValue = 60;
    let r = (hash & 0xff0000) >> 16;
    let g = (hash & 0x00ff00) >> 8;
    let b = hash & 0x0000ff;
    r = Math.min(255, r + lighterValue);
    g = Math.min(255, g + lighterValue);
    b = Math.min(255, b + lighterValue);
    // Format the RGB components into a valid RGB color code
    const colorCode = `rgb(${r}, ${g}, ${b})`;
    if (text !== "null") {
      return colorCode;
    } else {
      return "white";
    }
  }
  const periods = () => {
    if (sectionData.section === "SSR" || sectionData.section === "Libr") {
      return ["p1", "p2", "Break", "p3", "p4", "p5", "Break", "p6"];
    } else if (
      sectionData.section.match(/\d+/g).map(Number)[0] === 10 ||
      sectionData.section.match(/\d+/g).map(Number)[0] === 9
    ) {
      return ["p1", "p2", "p3", "Break", "p4", "p5", "Break", "p6"];
    } else {
      return ["p1", "p2", "Break", "p3", "p4", "p5", "Break", "p6"];
      // return ["p1", "p2", "Break", "p3", "p4", "p5", "p6"];//Ramadan
    }
  };
  const Frperiods = () => {
    if (sectionData.section === "SSR" || sectionData.section === "Libr") {
      return ["p1", "Break", "p2", "p3", "p4"];
    } else if (
      sectionData.section.match(/\d+/g).map(Number)[0] === 10 ||
      sectionData.section.match(/\d+/g).map(Number)[0] === 9
    ) {
      return ["p1", "p2", "Break", "p3", "p4"];
    } else {
      return ["p1", "Break", "p2", "p3", "p4"];
    }
  };
  const timing = () => {
    if (sectionData.section === "SSR" || sectionData.section === "Libr") {
      return [
        "8:00-9:00",
        "9:00-10:00",
        "10:00-10:20",
        "10:20-11:20",
        "11:20-12:20",
        "12:20-13:20",
        "13:20-13:30",
        "13:30-14:30",
      ];
    } else if (
      sectionData.section.match(/\d+/g).map(Number)[0] === 10 ||
      sectionData.section.match(/\d+/g).map(Number)[0] === 9
    ) {
      //Ramadan Hours
      // return [
      //   "9:45-10:25",
      //   "10:25:11:05",
      //   "11:05:-11:45",
      //   "11:45:-12:00",
      //   "12:00-12:30",
      //   "12:30-13:00",
      //   "13:00-13:30",
      // ];

      return [
        "8:00-9:00",
        "09:00:10:00",
        "10:00:-11:00",
        "11:00-11:20",
        "11:20-12:20",
        "12:20-13:20",
        "13:20-13:30",
        "13:30-14:30",
      ];
    } else {
      //Ramadan Hours
      // return [
      //   "9:45-10:25",
      //   "10:25:11:05",
      //   "11:05:-11:20",
      //   "11:20:-12:00",
      //   "12:00-12:30",
      //   "12:30-13:00",
      //   "13:00-13:30",
      // ];
      //    return [
      //   "8:00-9:00",
      //   "9:00-10:00",
      //   "10:00-10:20",
      //   "10:20-11:20",
      //   "11:20-12:20",
      //   "12:20-13:20",
      //   "13:20-13:30",
      //   "13:30-14:30",
      // ];
      return [
        "8:00-9:00",
        "9:00-10:00",
        "10:00-10:20",
        "10:20-11:20",
        "11:20-12:20",
        "12:20-13:20",
        "13:20-13:30",
        "13:30-14:30",
      ];
    }
  };
  const Frtiming = () => {
    if (sectionData.section === "SSR" || sectionData.section === "Libr") {
      return [
        "8:00-8:45",
        "8:45-9:15",
        "9:15-10:00",
        "10:00-10:45",
        "10:45-11:30",
      ];
    } else if (
      sectionData.section.match(/\d+/g).map(Number)[0] === 10 ||
      sectionData.section.match(/\d+/g).map(Number)[0] === 9
    ) {
      //Ramadan Hours
      // return [
      //   "9:10-9:50",
      //   "9:50-10:30",
      //   "10:30-10:40",
      //   "10:40-11:30",
      //   "11:20-12:00",
      // ];
      return [
        "8:00-8:45",
        "8:45-9:30",
        "9:30-10:00",
        "10:00-10:45",
        "10:45-11:30",
      ];
    } else {
      //Ramadan Hours
      // return [
      //   "9:10-9:50",
      //   "9:50-10:00",
      //   "10:00-10:40",
      //   "10:40-11:20",
      //   "11:20-12:00",
      // ];
      return [
        "8:00-8:45",
        "8:45-9:15",
        "9:15-10:00",
        "10:00-10:45",
        "10:45-11:30",
      ];
    }
  };
  const homeroomTD = () => {
    return (
      <td>
        <p className="homeroomTeacher">
          {sectionData.homeroomteacher.split(" ").slice(0, 1).join(" ") +
            " " +
            sectionData.homeroomteacher.split(" ").slice(1, 2).join(" ") +
            " "}
        </p>
        <p
          className={
            sectionData.homeroomClass !== "" ? "room homeroomClass" : ""
          }
        >
          {sectionData.homeroomClass}
        </p>
      </td>
    );
  };
  return (
    <div>
      <div>
        <table className="schedule-table">
          <tbody>
            <tr>
              <th></th>
              <th className="timetableHeader">Homeroom</th>
              {periods().map((p, pIndex) => {
                return (
                  <th key={pIndex + 10}>
                    {p}
                    <p>{timing()[pIndex]}</p>
                  </th>
                );
              })}
            </tr>
            {["m", "t", "w", "th"].map((rowData, rowIndex) => (
              <tr key={rowIndex}>
                <th className="timetableHeader">
                  {
                    ["Monday", "Tuesday", "Wednesday", "Thursday"][
                      ["m", "t", "w", "th"].indexOf(rowData)
                    ]
                  }
                </th>
                {/* hi */}
                {homeroomTD()}
                {periods().map((p, pIndex) => {
                  if (p !== "Break") {
                    return (
                      <td
                        key={pIndex}
                        style={{
                          backgroundColor: `${generateColorCode(
                            sectionData[rowData + "Sub"][p]
                          )}`,
                          color: "white",
                        }}
                      >
                        <div className="cell-content">
                          <div
                            className={
                              sectionData[rowData + "Sub"][p] !== "null"
                                ? "subject"
                                : ""
                            }
                          >
                            {sectionData[rowData + "Sub"][p] !== "null"
                              ? sectionData[rowData + "Sub"][p]
                              : ""}
                          </div>
                          <div
                            className={
                              sectionData[rowData + "Sub"][p] !== "null"
                                ? "teacher"
                                : ""
                            }
                          >
                            {sectionData[rowData + "Teacher"][p] !== "null"
                              ? sectionData[rowData + "Teacher"][p]
                                  .split(" ")
                                  .slice(0, 1)
                                  .join(" ") +
                                " " +
                                sectionData[rowData + "Teacher"][p]
                                  .split(" ")
                                  .slice(1, 2)
                                  .join(" ")
                              : ""}
                          </div>
                          <div
                            className={
                              sectionData[rowData + "Sub"][p] !== "null"
                                ? "room"
                                : ""
                            }
                          >
                            {sectionData[rowData + "Rooms"][p] !== "null"
                              ? sectionData[rowData + "Rooms"][p]
                              : ""}
                          </div>
                        </div>
                      </td>
                    );
                  } else {
                    return <td></td>;
                  }
                })}
              </tr>
            ))}
            <tr>
              <th></th>
              <th></th>
              {Frperiods().map((p, pIndex) => {
                return (
                  <th key={pIndex + 10}>
                    {p}
                    <p>{Frtiming()[pIndex]}</p>
                  </th>
                );
              })}
            </tr>
            <tr>
              <th>Friday</th>
              {homeroomTD()}

              {Frperiods().map((p, pIndex) => {
                if (p !== "Break") {
                  return (
                    <td
                      key={pIndex}
                      style={{
                        backgroundColor: `${generateColorCode(
                          sectionData["fSub"][p]
                        )}`,
                        color: "white",
                      }}
                    >
                      <div className="cell-content">
                        <div
                          className={
                            sectionData["fSub"][p] !== "null" ? "subject" : ""
                          }
                        >
                          {sectionData["fSub"][p] !== "null"
                            ? sectionData["fSub"][p]
                            : ""}
                        </div>
                        <div
                          className={
                            sectionData["fSub"][p] !== "null" ? "teacher" : ""
                          }
                        >
                          {sectionData["fTeacher"][p] !== "null"
                            ? sectionData["fTeacher"][p]
                                .split(" ")
                                .slice(0, 1)
                                .join(" ") +
                              " " +
                              sectionData["fTeacher"][p]
                                .split(" ")
                                .slice(1, 2)
                                .join(" ")
                            : ""}
                        </div>
                        <div
                          className={
                            sectionData["fSub"][p] !== "null" ? "room" : ""
                          }
                        >
                          {sectionData["fRooms"][p] !== "null"
                            ? sectionData["fRooms"][p]
                            : ""}
                        </div>
                      </div>
                    </td>
                  );
                } else {
                  return <td></td>;
                }
              })}
            </tr>
          </tbody>
        </table>
      </div>

      <div>
        <br></br>
        <table className="schedule-table">
          <tbody></tbody>
        </table>
      </div>
    </div>
  );
};

export default TimetableSection;
