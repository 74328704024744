import GroupsPage from "./InterventionAfterSchool/GroupsPage";
import StudentsAtRisk from "./StudentsAtRisk/StudentsAtRisk";
import Newlogin from "./views/NewLogin";

function App() {
  return (
    <div className="App">
      <Newlogin />
      {/* <StudentsAtRisk /> */}
    </div>
  );
}

export default App;
