import React, { useState, useEffect } from "react";
import "./timetableNew.css";
import generalQuery from "../generalQuery";
// import classes from "./timetableData/classes";
import { CircularProgress } from "@material-ui/core";
import TimetableSection from "./TimetableSectionOriginal";
import TimetableTeacher from "./timetableTeacher";
import TimetableRooms from "./timetableRooms";
import TimetablePrinting from "./timetablePrinting";
import Backarrow from "./BackArrow";
import SectionsTimetablePrinting from "./SectionsTimetablePrinting";
import edupic from "./edupage.png";
import Edupage from "./Edupage";
const TimetableNew = () => {
  // return ;
  const [teachersObject, setTeachersObject] = useState([]);
  const [sectionObjects, setSectionObjects] = useState([]);
  const [roomsObjects, setRoomsObjects] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [selectedClass, setSelectedClass] = useState("8A");
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [selectedRoom, setSelectedRoom] = useState("");
  const [teacherssummary, setTeacherssummary] = useState([]);
  const [subjectsummary, setSubjectsummary] = useState([]);
  const [classes, setClasses] = useState([]);
  const [teacherplanningrooms, setTeacherplanningrooms] = useState([]);
  const getSubjectsForRooms = (d, roomResults, r) => {
    return {
      p1:
        roomResults.filter((e) => e[d + 1] === r).length > 0
          ? roomResults.filter((e) => e[d + 1] === r)[0].class
          : "",
      p2:
        roomResults.filter((e) => e[d + 2] === r).length > 0
          ? roomResults.filter((e) => e[d + 2] === r)[0].class
          : "",
      p3:
        roomResults.filter((e) => e[d + 3] === r).length > 0
          ? roomResults.filter((e) => e[d + 3] === r)[0].class
          : "",
      p4:
        roomResults.filter((e) => e[d + 4] === r).length > 0
          ? roomResults.filter((e) => e[d + 4] === r)[0].class
          : "",
      p5:
        roomResults.filter((e) => e[d + 5] === r).length > 0
          ? roomResults.filter((e) => e[d + 5] === r)[0].class
          : "",
      p6:
        roomResults.filter((e) => e[d + 6] === r).length > 0
          ? roomResults.filter((e) => e[d + 6] === r)[0].class
          : "",
    };
  };
  const getSubjectsForRoomsFr = (d, roomResults, r) => {
    return {
      p1:
        roomResults.filter((e) => e[d + 1] === r).length > 0
          ? roomResults.filter((e) => e[d + 1] === r)[0].class
          : "",
      p2:
        roomResults.filter((e) => e[d + 2] === r).length > 0
          ? roomResults.filter((e) => e[d + 2] === r)[0].class
          : "",
      p3:
        roomResults.filter((e) => e[d + 3] === r).length > 0
          ? roomResults.filter((e) => e[d + 3] === r)[0].class
          : "",
      p4:
        roomResults.filter((e) => e[d + 4] === r).length > 0
          ? roomResults.filter((e) => e[d + 4] === r)[0].class
          : "",
    };
  };

  useEffect(() => {
    generalQuery("select * from teacherssummary").then((resulTeachers) => {
      setTeacherssummary(resulTeachers);
      let arr = [];
      let arrTeachersObject = [];
      resulTeachers.forEach((r) => {
        arrTeachersObject.push({
          teacher: r.teacher,
          sub: r.sub,
          m: {
            p1: r.m1,
            p2: r.m2,
            p3: r.m3,
            p4: r.m4,
            p5: r.m5,
            p6: r.m6,
          },
          t: {
            p1: r.t1,
            p2: r.t2,
            p3: r.t3,
            p4: r.t4,
            p5: r.t5,
            p6: r.t6,
          },
          w: {
            p1: r.w1,
            p2: r.w2,
            p3: r.w3,
            p4: r.w4,
            p5: r.w5,
            p6: r.w6,
          },
          th: {
            p1: r.th1,
            p2: r.th2,
            p3: r.th3,
            p4: r.th4,
            p5: r.th5,
            p6: r.th6,
          },
          f: {
            p1: r.f1,
            p2: r.f2,
            p3: r.f3,
            p4: r.f4,
          },
        });
        arr.push(r.teacher);
      });
      // Setting the teachers drop-down menu
    
      setTeachersObject(arrTeachersObject);
      setTeachers(arr.sort((a, b) => (a > b ? 1 : -1)));
      generalQuery("select * from roomsummary").then((roomResults) => {
        let arr = [];
        let arrRooms = [];
        let arrClasses = [];
        roomResults.forEach((r) => {
          ["m", "t", "w", "th", "f"].forEach((d) => {
            [1, 2, 3, 4, 5, 6].forEach((p) => {
              if (
                d + p !== "f5" &&
                d + p !== "f6" &&
                arr.indexOf(r[d + p]) === -1 &&
                r[d + p] !== "N" &&
                r[d + p] !== "x" &&
                r[d + p] !== "null"
              ) {
                arr.push(r[d + p]);
              }
            });
          });
          arrClasses.push(r.class);
        });
        setClasses(arrClasses);
        setRooms(arr.sort((a, b) => (a > b ? 1 : -1)));

        generalQuery("select * from classteacher").then((classTeacherResp) => {
          generalQuery("select * from subjectsummary").then((respSub) => {
            setSubjectsummary(respSub);
            generalQuery("select * from homerooms").then((homerooms) => {
              arr.forEach((r) => {
                arrRooms.push({
                  room: r,
                  mClass: getSubjectsForRooms("m", roomResults, r),
                  tClass: getSubjectsForRooms("t", roomResults, r),
                  wClass: getSubjectsForRooms("w", roomResults, r),
                  thClass: getSubjectsForRooms("th", roomResults, r),
                  fClass: getSubjectsForRoomsFr("f", roomResults, r),
                });
              });
              setRoomsObjects(arrRooms);
              let arrSection = [];
              respSub.forEach((s) => {
                let sectionTeachers = classTeacherResp.filter(
                  (e) => e.class === s.class
                )[0];
                let sectionRooms = roomResults.filter(
                  (e) => e.class === s.class
                )[0];
                let homeroomteacher = homerooms.filter(
                  (h) => h.class === s.class
                )[0]
                  ? homerooms.filter((h) => h.class === s.class)[0].teacher
                  : "";

                let homeroomClass = homerooms.filter(
                  (h) => h.class === s.class
                )[0]
                  ? homerooms.filter((h) => h.class === s.class)[0].homeroom
                  : "";

                arrSection.push({
                  section: s.class,
                  homeroomteacher: homeroomteacher,
                  homeroomClass: homeroomClass,
                  mSub: {
                    p1: s.m1,
                    p2: s.m2,
                    p3: s.m3,
                    p4: s.m4,
                    p5: s.m5,
                    p6: s.m6,
                  },
                  tSub: {
                    p1: s.t1,
                    p2: s.t2,
                    p3: s.t3,
                    p4: s.t4,
                    p5: s.t5,
                    p6: s.t6,
                  },
                  wSub: {
                    p1: s.w1,
                    p2: s.w2,
                    p3: s.w3,
                    p4: s.w4,
                    p5: s.w5,
                    p6: s.w6,
                  },
                  thSub: {
                    p1: s.th1,
                    p2: s.th2,
                    p3: s.th3,
                    p4: s.th4,
                    p5: s.th5,
                    p6: s.th6,
                  },
                  fSub: {
                    p1: s.f1,
                    p2: s.f2,
                    p3: s.f3,
                    p4: s.f4,
                  },
                  mTeacher: {
                    p1: sectionTeachers.m1,
                    p2: sectionTeachers.m2,
                    p3: sectionTeachers.m3,
                    p4: sectionTeachers.m4,
                    p5: sectionTeachers.m5,
                    p6: sectionTeachers.m6,
                  },
                  tTeacher: {
                    p1: sectionTeachers.t1,
                    p2: sectionTeachers.t2,
                    p3: sectionTeachers.t3,
                    p4: sectionTeachers.t4,
                    p5: sectionTeachers.t5,
                    p6: sectionTeachers.t6,
                  },
                  wTeacher: {
                    p1: sectionTeachers.w1,
                    p2: sectionTeachers.w2,
                    p3: sectionTeachers.w3,
                    p4: sectionTeachers.w4,
                    p5: sectionTeachers.w5,
                    p6: sectionTeachers.w6,
                  },
                  thTeacher: {
                    p1: sectionTeachers.th1,
                    p2: sectionTeachers.th2,
                    p3: sectionTeachers.th3,
                    p4: sectionTeachers.th4,
                    p5: sectionTeachers.th5,
                    p6: sectionTeachers.th6,
                  },
                  fTeacher: {
                    p1: sectionTeachers.f1,
                    p2: sectionTeachers.f2,
                    p3: sectionTeachers.f3,
                    p4: sectionTeachers.f4,
                  },
                  mRooms: {
                    p1: sectionRooms.m1,
                    p2: sectionRooms.m2,
                    p3: sectionRooms.m3,
                    p4: sectionRooms.m4,
                    p5: sectionRooms.m5,
                    p6: sectionRooms.m6,
                  },
                  tRooms: {
                    p1: sectionRooms.t1,
                    p2: sectionRooms.t2,
                    p3: sectionRooms.t3,
                    p4: sectionRooms.t4,
                    p5: sectionRooms.t5,
                    p6: sectionRooms.t6,
                  },
                  wRooms: {
                    p1: sectionRooms.w1,
                    p2: sectionRooms.w2,
                    p3: sectionRooms.w3,
                    p4: sectionRooms.w4,
                    p5: sectionRooms.w5,
                    p6: sectionRooms.w6,
                  },
                  thRooms: {
                    p1: sectionRooms.th1,
                    p2: sectionRooms.th2,
                    p3: sectionRooms.th3,
                    p4: sectionRooms.th4,
                    p5: sectionRooms.th5,
                    p6: sectionRooms.th6,
                  },
                  fRooms: {
                    p1: sectionRooms.f1,
                    p2: sectionRooms.f2,
                    p3: sectionRooms.f3,
                    p4: sectionRooms.f4,
                  },
                });
              });
   
              setSectionObjects(arrSection);
            });
          });
        });
      });
    });
    generalQuery("select * from teacherplanningrooms").then((resp) => {
      setTeacherplanningrooms(resp);
    });
  }, []);
  if (roomsObjects.length === 0) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <img src={edupic} width={50} style={{ cursor: "pointer" }}></img>
      <Edupage />
      {/* <h3>TALA Week Timetable</h3> */}
      {(roomsObjects.length > 0 &&
        sessionStorage.getItem("user") !== "student") ||
      "timetable" ? (
        ""
      ) : (
        <Backarrow />
      )}
      {/* {sessionStorage.getItem("user") === "timetable" && ( */}
        <>
          <div className="container">
            <TimetablePrinting
              teachersObject={teachersObject}
              sectionObjects={sectionObjects}
              roomsObjects={roomsObjects}
              teacherssummary={teacherssummary}
              subjectsummary={subjectsummary}
              teacherplanningrooms={teacherplanningrooms}
            />
            <SectionsTimetablePrinting
              teachersObject={teachersObject}
              sectionObjects={sectionObjects}
              roomsObjects={roomsObjects}
              teacherssummary={teacherssummary}
              subjectsummary={subjectsummary}
              classes={classes}
            />
          </div>
        </>
      {/* )} */}
      <div id="selection" className="container">
        <select
          id="room"
          className="select"
          onChange={(e) => {
            setSelectedClass("");
            setSelectedTeacher("");
            setSelectedRoom(e.target.value);
            document.getElementById("class").value = "";
            document.getElementById("teacher").value = "";
          }}
        >
          <option value="">--Room--</option>
          {rooms.map((r) => {
            return <option key={r}>{r}</option>;
          })}
        </select>
        <select
          id="class"
          className="select"
          onChange={(e) => {
            setSelectedRoom("");
            setSelectedTeacher("");
            setSelectedClass(e.target.value);
            document.getElementById("room").value = "";
            document.getElementById("teacher").value = "";
          }}
        >
          {classes.map((c) => {
            return <option key={c}>{c}</option>;
          })}
          <option value="">--Class--</option>
        </select>
        <select
          id="teacher"
          className="select"
          onChange={(e) => {
            setSelectedClass("");
            setSelectedRoom("");
            setSelectedTeacher(e.target.value);
            document.getElementById("room").value = "";
            document.getElementById("class").value = "";
          }}
        >
          <option value="">--Teacher--</option>
          {teachers.map((t) => {
            return <option key={t}>{t}</option>;
          })}
        </select>
      </div>
      <div className="container">
        {selectedClass !== "" &&
        selectedRoom === "" &&
        selectedTeacher === "" &&
        sectionObjects.length > 0 ? (
          <TimetableSection
            sectionData={
              sectionObjects.filter((s) => s.section === selectedClass)[0]
            }
          
          />
        ) : selectedRoom !== "" &&
          selectedClass === "" &&
          selectedTeacher === "" ? (
          <TimetableRooms
            roomsObjects={
              roomsObjects.filter((r) => r.room === selectedRoom)[0]
            }
            sectionObjects={sectionObjects}
          />
        ) : selectedTeacher !== "" &&
          selectedClass === "" &&
          selectedRoom === "" ? (
          <TimetableTeacher
            teachersObject={
              teachersObject.filter((t) => t.teacher === selectedTeacher)[0]
            }
            teacherplanningrooms={teacherplanningrooms}
            sectionObjects={sectionObjects}
            classes={classes}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default TimetableNew;
