import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2"; // Ensure correct import of Bar component
import generalQuery from "../generalQuery"; // Ensure this is exported correctly
import "chart.js/auto"; // Correct auto import for Chart.js
import "./InterventionAttendanceDashboard.css"; // Import your styles

const podMapping = {
  "G10-A": "D", "G10-B": "D", "G10-C": "D", "G10-D": "D", "G10-E": "E",
  "G10-F": "E", "G10-G": "E", "G10-H": "E", "G11-A": "I", "G11-B": "I",
  "G11-C": "I", "G11-D": "J", "G11-E": "J", "G11-F": "J", "G8-A": "A",
  "G8-B": "A", "G8-C": "A", "G8-D": "A", "G8-E": "B", "G8-F": "B",
  "G8-G": "B", "G8-H": "C", "G8-I": "C", "G8-J": "C", "G9-A": "F",
  "G9-B": "F", "G9-C": "F", "G9-D": "F", "G9-E": "G", "G9-F": "G",
  "G9-G": "G", "G9-H": "G", "G9-I": "H", "G9-J": "H", "G9-K": "H",
};

const InterventionAttendanceDashboard = () => {
  const today = new Date();
  const todayInputFormat = today.toISOString().split("T")[0];

  const formatDateForDataset = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear().toString().slice(-2);
    return `${day}-${month}-${year}`;
  };

  const [attendanceData, setAttendanceData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(todayInputFormat);
  const [attendanceStats, setAttendanceStats] = useState({});
  const [podAttendanceStats, setPodAttendanceStats] = useState({});
  const [selectedAttendanceFilter, setSelectedAttendanceFilter] = useState("All");

  useEffect(() => {
    const fetchData = async () => {
      const data = await generalQuery("select * from interventionsessiondata");
      setAttendanceData(data);

      const formattedToday = formatDateForDataset(new Date());
      console.log(formattedToday)
      const filtered = data.filter((item) => item.date === formattedToday);
      setFilteredData(filtered);
      calculateStats(filtered);
    };
    fetchData();
  }, []);
  const overallPresentPercentage =
  attendanceStats.total > 0 ? ((attendanceStats.present / attendanceStats.total) * 100).toFixed(2) : 0;
const overallAbsentPercentage =
  attendanceStats.total > 0 ? ((attendanceStats.absent / attendanceStats.total) * 100).toFixed(2) : 0;
const overallLatePercentage =
  attendanceStats.total > 0 ? ((attendanceStats.late / attendanceStats.total) * 100).toFixed(2) : 0;

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setSelectedDate(selectedDate);

    const formattedDate = formatDateForDataset(new Date(selectedDate));
    const filtered = attendanceData.filter((item) => item.date === formattedDate);
    setFilteredData(filtered);
    calculateStats(filtered);
  };

  const handleAttendanceFilterChange = (e) => {
    setSelectedAttendanceFilter(e.target.value);
  };
 // Custom plugin to display percentages on top of the bars
 const chartOptions = {
  plugins: {
    legend: { display: false },
    tooltip: { enabled: true },
    datalabels: {
      display: true,
      color: "black",
      font: {
        weight: "bold",
      },
      formatter: function (value, context) {
        const total = attendanceStats.total;
        const percentage = ((value / total) * 100).toFixed(2);
        return `${percentage}%`;
      },
    },
  },
};
  const applyAttendanceFilter = (data) => {
    if (selectedAttendanceFilter === "All") {
      return data;
    } else {
      return data.filter((item) => item.attendance === selectedAttendanceFilter);
    }
  };

  const mapClassToPod = (studentClass) => podMapping[studentClass] || "Unknown";

  const calculateStats = (data) => {
    const overallAttendance = {
      total: 0,
      present: 0,
      absent: 0,
      late: 0,
    };

    const podAttendance = {};

    data.forEach((item) => {
      overallAttendance.total += 1;
      if (item.attendance === "Present") {
        overallAttendance.present += 1;
      } else if (item.attendance === "Absent") {
        overallAttendance.absent += 1;
      } else if (item.attendance === "Late") {
        overallAttendance.late += 1;
      }

      const pod = mapClassToPod(item.class);
      if (!podAttendance[pod]) {
        podAttendance[pod] = { total: 0, present: 0, absent: 0, late: 0 };
      }

      podAttendance[pod].total += 1;
      if (item.attendance === "Present") {
        podAttendance[pod].present += 1;
      } else if (item.attendance === "Absent") {
        podAttendance[pod].absent += 1;
      } else if (item.attendance === "Late") {
        podAttendance[pod].late += 1;
      }
    });

    setAttendanceStats(overallAttendance);
    setPodAttendanceStats(podAttendance);
  };

  // Sort pods by attendance ratio in descending order
  const sortedPodAttendanceStats = Object.entries(podAttendanceStats)
    .map(([pod, stats]) => ({
      pod,
      presentRatio: (stats.present / stats.total) * 100,
      absentRatio: (stats.absent / stats.total) * 100,
      lateRatio: (stats.late / stats.total) * 100,
    }))
    .sort((a, b) => b.presentRatio - a.presentRatio); // Sort by present ratio in descending order

  // Pod attendance chart data
  const podChartData = {
    labels: sortedPodAttendanceStats.map((podStats) => podStats.pod),
    datasets: [
      {
        label: "Present",
        data: sortedPodAttendanceStats.map((podStats) => podStats.presentRatio),
        backgroundColor: "#4caf50",
      },
      {
        label: "Absent",
        data: sortedPodAttendanceStats.map((podStats) => podStats.absentRatio),
        backgroundColor: "#f44336",
      },
      {
        label: "Late",
        data: sortedPodAttendanceStats.map((podStats) => podStats.lateRatio),
        backgroundColor: "#ff9800",
      },
    ],
  };

  const overallChartData = {
    labels: ["Present", "Absent", "Late"],
    datasets: [
      {
        label: "Overall Attendance",
        data: [
          attendanceStats.present,
          attendanceStats.absent,
          attendanceStats.late,
        ],
        backgroundColor: ["#4caf50", "#f44336", "#ff9800"],
      },
    ],
  };

  const processValue = (value) => (value === null || value === undefined ? "N/A" : value);

  const sortTable = (field) => {
    const sortedData = [...filteredData].sort((a, b) =>
      processValue(a[field]).localeCompare(processValue(b[field]))
    );
    setFilteredData(sortedData);
  };

  const finalFilteredData = applyAttendanceFilter(filteredData);

  return (
    <div className="dashboard-container">
      <h2>Intervention Attendance Dashboard</h2>

      <div className="date-picker">
        <label htmlFor="date">Select Date: </label>
        <input
          className="select"
          type="date"
          id="date"
          value={selectedDate}
          onChange={handleDateChange}
        />
      </div>

      <div className="attendance-filter">
        <label htmlFor="attendanceFilter">Filter by Attendance: </label>
        <select
          className="select"
          id="attendanceFilter"
          value={selectedAttendanceFilter}
          onChange={handleAttendanceFilterChange}
        >
          <option value="All">All</option>
          <option value="Present">Present</option>
          <option value="Absent">Absent</option>
          <option value="Late">Late</option>
        </select>
      </div>
      <div className="chart-container-wrapper">
      {/* Overall Attendance Chart */}
      {/* Overall Attendance Chart */}
     
      <div className="chart-container">

        <h3 style={{color:"red"}}>
          {overallPresentPercentage}%
        </h3>
       
        <Bar data={overallChartData} />
      </div>

      {/* Pod Attendance Chart */}
      <div className="chart-container">
        <h3>Pod Attendance Percentages </h3>
        <Bar data={podChartData} />
      </div>
      </div>
      <table className="attendance-table">
        <thead>
          <tr>
            <th onClick={() => sortTable("esis")}>eSIS</th>
            <th onClick={() => sortTable("name")}>Name</th>
            <th onClick={() => sortTable("class")}>Class</th>
            <th onClick={() => sortTable("attendance")}>Attendance</th>
          </tr>
        </thead>
        <tbody>
          {finalFilteredData.map((item) => (
            <tr
              key={item.esis}
              className={
                item.attendance === "Absent"
                  ? "absent-row"
                  : item.attendance === "Late"
                  ? "late-row"
                  : ""
              }
            >
              <td>{processValue(item.esis)}</td>
              <td>{processValue(item.name)}</td>
              <td>{processValue(item.class)}</td>
              <td>{processValue(item.attendance)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InterventionAttendanceDashboard;
