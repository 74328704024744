import React, { useState, useEffect } from "react";
import generalQuery from "../generalQuery";
import datesArr from "./timetableInt";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import "./TeachersIntervention.css"; // Add styles for the component here

const TeachersIntervention = () => {
  const [teachers, setTeachers] = useState([]);
  const [activeTeacher, setActiveTeacher] = useState(null);
  const [timetable, setTimetable] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null); // Track selected session
  const [students, setStudents] = useState([]); // Students related to the selected session
  const [attendance, setAttendance] = useState({}); // Track attendance status
  const [loading, setLoading] = useState(true); // Add loading state
  const [open, setOpen] = useState(false); // State for modal
  const [intGroupsData, setIntGroupsData] = useState([]);
  const [sessionId, setsessionId] = useState("");
  const [sessionDate, setSessionDate] = useState("");
  const [teacherId, setTeacherId] = useState("");
  useEffect(() => {
    // Start loading when fetching data
    console.log(sessionStorage.getItem("id"));
    setLoading(true);
    generalQuery(`select * from interventiongroups25`).then((results) => {
      if (results && results.length > 0) {
        setIntGroupsData(results);
        // Extract unique teacher names and filter out null/undefined values
        const uniqueTeachers = [
          ...new Set(
            results
              .map((item) => item.teacher)
              .filter((teacher) => teacher != null && teacher !== "")
          ),
        ].sort((a, b) => a.localeCompare(b));

        setTeachers(uniqueTeachers);
      }
      setLoading(false); // End loading when data is fetched
    });
  }, []);

  const handleTeacherClick = (teacher) => {
    setStudents([]);
    setActiveTeacher(teacher);
    setLoading(true); // Start loading during teacher transition

    // Filter the results based on the selected teacher and datesArr
    // generalQuery(`select * from interventiongroups25`).then((results) => {
    const teacherGroups = intGroupsData.filter(
      (group) => group.teacher === teacher
    );
    setTeacherId(teacherGroups[0].teacher_id);
    const filteredTimetable = datesArr
      .filter((dateObj) =>
        teacherGroups.some((group) => group.cluster === dateObj.cluster)
      )
      .sort((a, b) => new Date(a.date) - new Date(b.date))
      .map((dateObj, index) => {
        const group = teacherGroups.find((g) => g.cluster === dateObj.cluster);

        return {
          session: (index + 1).toString(),
          day: dateObj.day,
          date: dateObj.date,
          cluster: dateObj.cluster,
          groupName: group.group,
          room: group.room,
        };
      });
    setTimetable(filteredTimetable);
    setLoading(false); // End loading after data is processed
    // });
  };

  const handleSessionClick = (session) => {
    setSelectedSession(session);
    setLoading(true);
    let session_id = session.date + session.groupName;
    setsessionId(session_id);
    setSessionDate(session.date);
    // Query to fetch session data from interventionsessiondata
    generalQuery(
      `SELECT * FROM interventionsessiondata WHERE date='${session.date}' and group_='${session.groupName}'`
    )
      .then((results) => {
        if (results.length > 0) {
          // If session data exists in interventionsessiondata, use it to set the state
          const sessionStudents = results.map((student) => ({
            "esis#": student.esis,
            name: student.name,
            class: student.class,
            eot: student.eot,
            quartile: intGroupsData.filter(
              (s) => s["esis#"] === student.esis
            )[0]["quartile"],
            map_percentile: intGroupsData.filter(
              (s) => s["esis#"] === student.esis
            )[0]["map_percentile"],
            objective: student.objective,
            successcriteria: student.successcriteria,
            are_you_ready_quiz: student.are_you_ready_quiz,
            end_of_session_quiz: student.end_of_session_quiz,
            checkpoint: student.checkpoint,
            attendance: student.attendance, // Use attendance if available, otherwise default to Present
          }));

          setStudents(sessionStudents);
        } else {
          intGroupsData.forEach((student) => {
            student.attendance = "Present";
            student.objective = "";
            student.successcriteria = "";
            student.are_you_ready_quiz = "";
            student.end_of_session_quiz = "";
            student.checkpoint = "";
          });
          const sessionStudents = intGroupsData.filter(
            (student) => student.group === session.groupName
          );

          setStudents(sessionStudents);

          // Initialize attendance state as "Present"
          const initialAttendance = {};
          sessionStudents.forEach((student) => {
            initialAttendance[student["esis#"]] = "Present";
          });
          setAttendance(initialAttendance);
          // });
        }

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching session data:", error);
        setLoading(false);
      });
  };

  const insertAttQuery = async () => {
    setLoading(true);
    const queries = students.map((data) => {
      return `DO $$ BEGIN IF NOT EXISTS (SELECT 1 FROM interventionSessionData WHERE session_id = '${sessionId}' AND esis = '${data["esis#"]}' AND name='${data.name}') THEN INSERT INTO interventionSessionData (session_id, group_, cluster, date, room, esis, name, class, attendance) VALUES ('${sessionId}', '${data.group}', '${data.cluster}', '${sessionDate}', '${data.room}', '${data["esis#"]}', '${data.name}', '${data.class}', '${data.attendance}'); ELSE UPDATE interventionSessionData SET attendance = '${data.attendance}' WHERE session_id = '${sessionId}' AND esis = '${data["esis#"]}'; END IF; END $$;`;
    });

    let queryString = queries.join(" ");
    await generalQuery(queryString).then(()=>{
      handleSessionClick(selectedSession)
    })
  };

  const handleAttendanceToggle = (esis) => {
    setAttendance((prev) => {
      const nextState =
        prev[esis] === "Present"
          ? "Absent"
          : prev[esis] === "Absent"
          ? "Late"
          : "Present";
      return { ...prev, [esis]: nextState };
    });
  };

  const handleGrowthPathClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getAttendanceClass = (status) => {
    switch (status) {
      case "Present":
        return "attendance-present";
      case "Absent":
        return "attendance-absent";
      case "Late":
        return "attendance-late";
      default:
        return "";
    }
  };

  return (
    <div className="teachers-intervention">
      <div className="left-column">
        <div style={{ textAlign: "center" }}>
          <h4>Teachers</h4>
        </div>
        <ul className="teachers-list">
          {teachers.map((teacher, index) => (
            <li
              key={index}
              className={activeTeacher === teacher ? "active" : ""}
              onClick={() => handleTeacherClick(teacher)}
            >
              {teacher}
            </li>
          ))}
        </ul>
      </div>
      <div className="middle-column">
        {loading ? (
          <div className="loader">
            <CircularProgress />
          </div>
        ) : activeTeacher ? (
          <div className="timetable">
            <div style={{ textAlign: "center" }}>
              <h4> {activeTeacher}</h4>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Session #</th>
                  {/* <th>Day</th> */}
                  <th>Date</th>
                  {/* <th>Cluster</th> */}
                  <th>Group Name</th>
                  <th>Room</th>
                </tr>
              </thead>
              <tbody>
                {timetable.map((session, index) => (
                  <tr
                    key={index}
                    className={selectedSession === session ? "active" : ""}
                    onClick={() => handleSessionClick(session)}
                  >
                    <td>{session.session}</td>
                    {/* <td></td> */}
                    <td>
                      {session.day}-{session.date}
                    </td>
                    {/* <td>{session.cluster}</td> */}
                    <td>{session.groupName}</td>
                    <td>{session.room}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>Select a teacher to see their timetable.</p>
        )}
      </div>
      <div className="right-column">
        {selectedSession ? (
          <div className="session-details">
            <div style={{ textAlign: "center" }}>
              <h4>Session Details</h4>
            </div>
            <p>
              <strong>Group:</strong> {selectedSession.groupName}
            </p>
            <p>
              <strong>Cluster:</strong> {selectedSession.cluster}
            </p>
            <p>
              <strong>Date:</strong> {selectedSession.date}
            </p>
            <p>
              <strong>Room:</strong> {selectedSession.room}
            </p>
            <h4>Students</h4>
            {loading ? (
              <div className="loader">
                <CircularProgress />
              </div>
            ) : (
              <>
                {[teacherId,'8888888'].includes(sessionStorage.getItem("id")) && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      insertAttQuery();
                    }}
                  >
                    Save Attendance
                  </Button>
                )}
                <table className="students-table">
                  <thead>
                    <tr>
                      {/* <th>eSIS</th> */}
                      <th>Name</th>
                      <th>Class</th>
                      <th>Attendance</th>
                      {/* <th>Actions</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {students
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((student, index) => (
                        <tr key={index}>
                          <td>
                            <span
                              style={{
                                float: "left",
                                color: "orange",
                                fontWeight: "bold",
                              }}
                            >
                              {" "}
                              {student["esis#"]}
                            </span>
                            <br></br>
                            {student.name}
                            <br></br>
                            <p
                              style={{
                                textAlign: "left",
                                color: "blue",
                                fontWeight: "bold",
                              }}
                            >
                              {student.quartile}{" "}
                              <span style={{ float: "right", color: "red" }}>
                                MAP%:{student.map_percentile}
                              </span>
                            </p>
                          </td>
                          <td>{student.class}</td>
                          <td>
                            <button
                              className={getAttendanceClass(student.attendance)}
                              onClick={() => {
                                if (
                                  [teacherId,'8888888'].includes(
                                    sessionStorage.getItem("id")
                                  )
                                ) {
                                  handleAttendanceToggle(student["esis#"]);
                                  if (student.attendance === "Present") {
                                    student.attendance = "Absent";
                                  } else if (student.attendance === "Absent") {
                                    student.attendance = "Late";
                                  } else if (student.attendance === "Late") {
                                    student.attendance = "Present";
                                  }
                                }
                              }}
                              disabled={
                                ![teacherId,'8888888'].includes(
                                  sessionStorage.getItem("id")
                                )
                              }
                            >
                              {student.attendance}
                            </button>
                          </td>
                          {/* <td>
                            <Button
                              variant="contained"
                              onClick={() => {
                                console.log(student);
                                handleGrowthPathClick();
                              }}
                            >
                              Obj.
                            </Button>
                          </td> */}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
        ) : (
          <p>Select a session to see details.</p>
        )}
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown
      >
        <Box className="modal-boxInervention">
          <Button onClick={handleClose} className="close-button">
            Close
          </Button>
          <h2 id="modal-modal-title">Set Objectives</h2>
          <p id="modal-modal-description">
            This is where the Growth Path details would go.
          </p>
        </Box>
      </Modal>
    </div>
  );
};

export default TeachersIntervention;
