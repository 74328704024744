import React, { useState, useEffect } from "react";
import teachersNames from "../data/teachersNames";
import classTeacherSubjectData from "../data/classTeacherSubject";
import generalQuery from "../generalQuery";
import "./StudentsAtRisk.css";
import CircularProgress from "@mui/material/CircularProgress";

const StudentsAtRisk = () => {
  const [selectedTeacher, setSelectedTeacher] = useState(
    teachersNames && teachersNames.length > 0 ? teachersNames[0] : ""
  );
  const [homerooms, setHomerooms] = useState([]);
  const [assessmentData, setAssessmentData] = useState([]);
  const [groupedData, setGroupedData] = useState({});
  const [loading, setLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  }); // Sorting configuration

  useEffect(() => {
    if (selectedTeacher === "") return;

    setLoading(true);

    const filteredData = classTeacherSubjectData.filter(
      (item) => item.teacher === selectedTeacher
    );
    const homeroomsList = filteredData.map((item) => item.homeroom);
    setHomerooms(homeroomsList);
    console.log(selectedTeacher);
    console.log(homeroomsList);
    if (homeroomsList.length > 0) {
      const query = `SELECT esis, name, homeroom, subject, SUM(CAST(NULLIF(grade, '') AS FLOAT)) AS total_grade, SUM(CAST(NULLIF(max, '') AS FLOAT)) AS total_max, FLOOR(SUM(CAST(NULLIF(grade, '') AS FLOAT)) * 100 / NULLIF(SUM(CAST(NULLIF(max, '') AS FLOAT)), 0)) AS percentage FROM assessmentsmarks WHERE academic_year = '24-25' AND homeroom IN (${homeroomsList
        .map((h) => `'${h}'`)
        .join(
          ", "
        )}) AND assessment_type = 'Key Assessment 1' AND subject IN ('english', 'math', 'arabic','science', 'islamic', 'social') GROUP BY esis, name, homeroom, subject ORDER BY esis, subject;`;

      generalQuery(query).then((data) => {
        const grouped = data.reduce((acc, item) => {
          if (!acc[item.esis]) {
            acc[item.esis] = {
              esis: item.esis,
              name: item.name,
              homeroom: item.homeroom,
              subjects: {},
            };
          }
          acc[item.esis].subjects[item.subject] = item.percentage;
          return acc;
        }, {});
        setGroupedData(grouped);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [selectedTeacher]);

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = Object.values(groupedData).sort((a, b) => {
    if (!sortConfig.key) return 0;

    const valueA =
      sortConfig.key === "name" || sortConfig.key === "homeroom"
        ? a[sortConfig.key]
        : a.subjects[sortConfig.key];
    const valueB =
      sortConfig.key === "name" || sortConfig.key === "homeroom"
        ? b[sortConfig.key]
        : b.subjects[sortConfig.key];

    if (valueA === undefined || valueB === undefined) return 0;

    if (sortConfig.direction === "ascending") {
      return valueA > valueB ? 1 : -1;
    } else {
      return valueA < valueB ? 1 : -1;
    }
  });

  return (
    <div className="students-at-risk-container">
      <h3 className="students-header">Students at Risk</h3>
      <h4 className="students-subheader">Key Assessment 1</h4>
      <h4 className="students-subheader">2024-2025</h4>

      <div className="students-filter-section">
        <div className="students-filter-label">Filter by teacher: </div>
        <select
          className="select"
          value={selectedTeacher}
          onChange={(e) => setSelectedTeacher(e.target.value)}
        >
          {teachersNames.map((teacher) => (
            <option key={teacher} value={teacher}>
              {teacher}
            </option>
          ))}
        </select>
      </div>

      {loading ? (
        <div className="students-loading-spinner">
          <CircularProgress />
        </div>
      ) : (
        <table className="students-risk-table">
          <thead>
            <tr>
              <th className="students-table-header">eSIS</th>
              <th
                className="students-table-header"
                onClick={() => handleSort("name")}
              >
                Student Name
              </th>
              <th
                className="students-table-header"
                onClick={() => handleSort("homeroom")}
              >
                Class
              </th>
              <th
                className="students-table-header"
                onClick={() => handleSort("english")}
              >
                English
              </th>
              <th
                className="students-table-header"
                onClick={() => handleSort("math")}
              >
                Math
              </th>
              <th
                className="students-table-header"
                onClick={() => handleSort("arabic")}
              >
                Arabic
              </th>
              <th
                className="students-table-header"
                onClick={() => handleSort("science")}
              >
                Science
              </th>
              <th
                className="students-table-header"
                onClick={() => handleSort("islamic")}
              >
                Islamic St
              </th>
              <th
                className="students-table-header"
                onClick={() => handleSort("social")}
              >
                Social St
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((row) => (
              <tr key={row.esis} className="students-row">
                <td className="students-cell">{row.esis}</td>
                <td className="students-cell">{row.name}</td>
                <td className="students-cell">{row.homeroom}</td>
                {[
                  "english",
                  "math",
                  "arabic",
                  "science",
                  "islamic",
                  "social",
                ].map((subject) => {
                  const percentage = row.subjects[subject] || "-";
                  return (
                    <td
                      key={subject}
                      className="students-cell"
                      style={{
                        backgroundColor:
                          percentage !== "-" && percentage < 60
                            ? "rgb(255,199,206)"
                            : "transparent",
                      }}
                    >
                      {percentage !== "-" ? `${percentage}%` : "-"}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default StudentsAtRisk;
