import React from "react";
import jsPDF from "jspdf";
import { font } from "../font";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Button } from "@material-ui/core";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import exportDataToExcel from "../exportDataToExcel";
const TimetablePrinting = ({
  teachersObject,
  roomsObjects,
  sectionObjects,
  teacherssummary,
  teacherplanningrooms,
}) => {
  const generatePDF = () => {
    var doc = new jsPDF("l");
    doc.addFileToVFS("IBMPlexSansArabic-Medium-normal.ttf", font);
    doc.addFont(
      "IBMPlexSansArabic-Medium-normal.ttf",
      "IBMPlexSansArabic-Medium",
      "normal"
    );
    const getColor = (sub) => {
      if (sub === "GCF") {
        return "red";
      } else if (sub === "Numeracy") {
        return "green";
      } else if (sub === "SAT") {
        return "rgb(255,0,255)";
      } else if (sub.slice(0, 4) === "Elec") {
        return "rgb(255,128,0)";
      } else if (sub.slice(0, 4) === "Inte") {
        return "rgb(0,204,0)";
      } else if (sub === "Literacy") {
        return "rgb(255,51,153)";
      } else if (sub === "Moral St") {
        return "rgb(0,255,255)";
      } else {
        return "black";
      }
    };
    doc.setFont("IBMPlexSansArabic-Medium"); // set font
    let len = teachersObject.length;
    let numberOfPages = Math.ceil(len / 18);

    const pageContent = (pn, arr) => {
      for (var i = 1; i < 30; ++i) {
        if ([7, 13, 19, 25].includes(i)) {
          doc.setLineWidth(0.4);
          doc.setDrawColor("black");
        } else {
          doc.setLineWidth(0.1);
          doc.setDrawColor("lightblue");
        }
        doc.line(45 + 8.4 * i, 5, 45 + 8.4 * i, 13 + (arr.length * 192) / 19);
      }
      let days = ["M", "T", "W", "Th", "F"];
      let periodsNum = ["1", "2", "3", "4", "5", "6"];
      days.forEach((d) => {
        periodsNum.forEach((p) => {
          doc.setFontSize(8);
          doc.setTextColor("black");
          if (!["F5", "F6"].includes(d + p)) {
            doc.text(
              d + p,
              55 +
                days.indexOf(d) * 42 +
                8.4 * (days.indexOf(d) + periodsNum.indexOf(p)),
              10
            );
          }
        });
      });
      doc.setFontSize(8);
      doc.text("mutanabi.ae", 4, 4);
      doc.text(new Date().toString().slice(0, 15), 267, 4);
      doc.setFontSize(15);
      doc.text("Teacher", 12, 11);
      arr.forEach((t) => {
        doc.setFontSize(10);
        doc.setTextColor("black");
        doc.setDrawColor("lightblue");
        doc.line(4, 13 + 10 * arr.indexOf(t), 288.6, 13 + 10 * arr.indexOf(t));
        let x = 6;
        let y = 10;
        doc.text(
          t.teacher.split(" ").slice(0, 1) +
            " " +
            t.teacher.split(" ").slice(1, 2),
          x,
          20 + y * arr.indexOf(t)
        );
        doc.setFontSize(6.5);
        doc.setTextColor("blue");
        doc.text(t.sub, 7 * x - 1.75, 20 + y * arr.indexOf(t));
        ["m", "t", "w", "th", "f"].forEach((d) => {
          [1, 2, 3, 4, 5, 6].forEach((n) => {
            if (
              typeof t[d]["p" + n] !== "undefined" &&
              t[d]["p" + n] !== "Planning" &&
              t[d]["p" + n] !== "SIP" &&
              t[d]["p" + n] !== "null" &&
              sectionObjects.filter((s) => s.section === t[d]["p" + n])[0][
                d + "Sub"
              ]["p" + n] !== t.sub
            ) {
              doc.setFontSize(4);
              doc.setTextColor(
                `${getColor(
                  sectionObjects.filter((s) => s.section === t[d]["p" + n])[0][
                    d + "Sub"
                  ]["p" + n]
                )}`
              );
              if (
                sectionObjects
                  .filter((s) => s.section === t[d]["p" + n])[0]
                  [d + "Sub"]["p" + n].slice(0, 4) === "Elec"
              ) {
                doc.text(
                  sectionObjects
                    .filter((s) => s.section === t[d]["p" + n])[0]
                    [d + "Sub"]["p" + n].slice(0, 6),
                  8.5 * n + 50.2 * ["m", "t", "w", "th", "f"].indexOf(d) + 46.2,
                  19 + y * arr.indexOf(t)
                );
              } else {
                doc.text(
                  sectionObjects.filter((s) => s.section === t[d]["p" + n])[0][
                    d + "Sub"
                  ]["p" + n],
                  8.5 * n + 50.2 * ["m", "t", "w", "th", "f"].indexOf(d) + 46.2,
                  19 + y * arr.indexOf(t)
                );
              }
            }
            doc.setFontSize(5);

            if (typeof t[d]["p" + n] !== "undefined") {
              if (t[d]["p" + n] !== "Planning" && t[d]["p" + n] !== "SIP") {
                if (t[d]["p" + n] !== "null") {
                  doc.setTextColor("black");
                  doc.setFontSize(7);
                  if (
                    t[d]["p" + n].split("_").length === 2 &&
                    !t[d]["p" + n].split("_").includes("Robotics")
                  ) {
                    doc.text(
                      "Intv",
                      8.5 * n +
                        50.2 * ["m", "t", "w", "th", "f"].indexOf(d) +
                        46.5,
                      17 + y * arr.indexOf(t)
                    );
                  } else if (t[d]["p" + n].split("_").includes("Robotics")) {
                    doc.text(
                      "G_" + t[d]["p" + n].slice(-1),
                      8.5 * n +
                        50.2 * ["m", "t", "w", "th", "f"].indexOf(d) +
                        46.5,
                      17 + y * arr.indexOf(t)
                    );
                  } else if (t[d]["p" + n].split("_").length > 2) {
                    doc.text(
                      "G_" + t[d]["p" + n].slice(-1),
                      8.5 * n +
                        50.2 * ["m", "t", "w", "th", "f"].indexOf(d) +
                        46.5,
                      17 + y * arr.indexOf(t)
                    );
                  } else {
                    doc.text(
                      t[d]["p" + n],
                      8.5 * n +
                        50.2 * ["m", "t", "w", "th", "f"].indexOf(d) +
                        46.5,
                      17 + y * arr.indexOf(t)
                    );
                  }

                  if (t[d]["p" + n].slice(0, 4) === "Elect") {
                    doc.text(
                      t[d]["p" + n].slice(0, 5),
                      8.5 * n +
                        50.2 * ["m", "t", "w", "th", "f"].indexOf(d) +
                        46.5,
                      17 + y * arr.indexOf(t)
                    );
                  }

                  doc.setTextColor("blue");
                  doc.setFontSize(4);
                  if (
                    roomsObjects.filter(
                      (c) => c[d + "Class"]["p" + n] === t[d]["p" + n]
                    ).length > 0
                  ) {
                    doc.text(
                      roomsObjects.filter(
                        (c) => c[d + "Class"]["p" + n] === t[d]["p" + n]
                      )[0].room,
                      8.5 * n +
                        50.2 * ["m", "t", "w", "th", "f"].indexOf(d) +
                        46.5,
                      21.8 + y * arr.indexOf(t)
                    );
                  }
                }
              } else if (t[d]["p" + n] == "Planning") {
                doc.setTextColor("green");
                doc.setFontSize(7);
                doc.setFillColor(255, 255, 0);
                doc.rect(
                  8 * n + 50.2 * ["m", "t", "w", "th", "f"].indexOf(d) + 48,
                  14 + y * arr.indexOf(t),
                  6,
                  9.5,
                  "F"
                );
                doc.text(
                  "PLN",
                  8 * n + 50.2 * ["m", "t", "w", "th", "f"].indexOf(d) + 48,
                  17.5 + y * arr.indexOf(t)
                );
                doc.setTextColor("red");
                doc.setFontSize(4);
                doc.text(
                  teacherplanningrooms.filter(
                    (a) => a.teacher === t.teacher
                  )[0][d + n],
                  8.5 * n + 50.2 * ["m", "t", "w", "th", "f"].indexOf(d) + 46.5,
                  21.8 + y * arr.indexOf(t)
                );
              } else if (t[d]["p" + n] == "SIP") {
                doc.setTextColor("green");
                doc.setFontSize(7);
                doc.setFillColor(184, 215, 233);
                doc.rect(
                  8 * n + 50.2 * ["m", "t", "w", "th", "f"].indexOf(d) + 48,
                  14 + y * arr.indexOf(t),
                  6,
                  9.5,
                  "F"
                );
                doc.text(
                  "SIP",
                  8 * n + 50.2 * ["m", "t", "w", "th", "f"].indexOf(d) + 48,
                  17.5 + y * arr.indexOf(t)
                );
               
               
              }
            }
          });
        });
      });
      doc.setLineWidth(0.5);
      doc.setDrawColor("black");
      doc.line(
        4,
        13 + (arr.length * 192) / 19,
        288.6,
        13 + (arr.length * 192) / 19
      );
      doc.line(288.6, 5, 288.6, 13 + (arr.length * 192) / 19);
      doc.line(53.4, 5, 53.4, 13 + (arr.length * 192) / 19);
      doc.line(4, 5, 4, 13 + (arr.length * 192) / 19);
      doc.line(4, 13, 288.6, 13);
      doc.line(4, 5, 288.6, 5);
    };
    pageContent(1, teachersObject.slice(0, 19));
    for (var n = 1; n < numberOfPages; ++n) {
      doc.addPage();
      pageContent(n, teachersObject.slice(19 * n, 19 * n + 19));
    }
    doc.save("Master Timetable");
  };
  return (
    <div>
      <Button
        style={{
          backgroundColor: "#2196f3",
          color: "#ffffff",
        }}
        startIcon={<PictureAsPdfIcon />}
        onClick={() => {
          generatePDF();
        }}
      >
        Master Timetable
      </Button>
      {teacherssummary.length > 0 && (
        <Button
          style={{
            backgroundColor: "#2196f3",
            color: "#ffffff",
            marginLeft: "5px",
            marginRight: "5px",
          }}
          startIcon={<BackupTableIcon />}
          onClick={(e) => {
            e.preventDefault();
            exportDataToExcel(
              teacherssummary,
              Object.keys(teacherssummary[0]),
              "teacherssummary"
            );
          }}
        >
          Master Timetable XLSX
        </Button>
      )}
      {/* {sectionObjects.length > 0 && (
        <button
          className="select"
          onClick={(e) => {
            e.preventDefault();
            exportDataToExcel(
              subjectsummary,
              Object.keys(subjectsummary[0]),
              "subjectsummary"
            );
          }}
        >
          Subject Summary XLSX
        </button>
      )} */}
    </div>
  );
};

export default TimetablePrinting;
