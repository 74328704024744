import React, { useState, useEffect } from 'react';
import { Table, Select, Input, Typography, Row, Col } from 'antd';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './AssessmentResultsTable.css'; // Importing the CSS file
import generalQuery from '../generalQuery';

const { Title } = Typography;
const { Option } = Select;

const AssessmentResultsTable = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [courseFilter, setCourseFilter] = useState('');
  const [teacherFilter, setTeacherFilter] = useState('');
  const [classFilter, setClassFilter] = useState('');
  const [growthFilter, setGrowthFilter] = useState('');
  const [lastUpdate, setLastUpdate] = useState('');

  useEffect(() => {
    // Fetch data from generalQuery

     generalQuery('select * from AssessmentResults_Updated').then(results=>{
      setData(results); 
      setFilteredData(results);
        // Get the greatest TestStartDate
        const latestDate = results.reduce((max, item) => item.TestStartDate > max ? item.TestStartDate : max, results[0].TestStartDate);
        setLastUpdate(latestDate);
     })

  }, []);

  // Filter data
  useEffect(() => {
    let filtered = data;

    if (courseFilter) {
      filtered = filtered.filter(item => item.Course === courseFilter);
    }
    if (teacherFilter) {
      filtered = filtered.filter(item => item.Teacher === teacherFilter);
    }
    if (classFilter) {
      filtered = filtered.filter(item => item.Class === classFilter);
    }
    if (growthFilter) {
      filtered = filtered.filter(item => item.FallToFallObservedGrowth === growthFilter);
    }

    setFilteredData(filtered);
  }, [courseFilter, teacherFilter, classFilter, growthFilter, data]);

  // Calculate percentages for the graph
  const getPercentileDistribution = () => {
    const total = filteredData.length;
    const percentiles = {
      '1st - 20th': 0,
      '21st - 40th': 0,
      '41st - 60th': 0,
      '61st - 80th': 0,
      '> 80th': 0,
    };

    filteredData.forEach(student => {
      const percentile = student.TestPercentile;
      if (percentile <= 20) percentiles['1st - 20th'] += 1;
      else if (percentile <= 40) percentiles['21st - 40th'] += 1;
      else if (percentile <= 60) percentiles['41st - 60th'] += 1;
      else if (percentile <= 80) percentiles['61st - 80th'] += 1;
      else percentiles['> 80th'] += 1;
    });

    return Object.keys(percentiles).map(key => ({
      range: key,
      percentage: ((percentiles[key] / total) * 100).toFixed(2)
    }));
  };

  const columns = [
    { title: 'Student ID', dataIndex: 'StudentID', key: 'StudentID' },
    { title: 'Name', dataIndex: 'Name', key: 'Name' },
    { title: 'Class', dataIndex: 'Class', key: 'Class' },
    { title: 'Teacher', dataIndex: 'Teacher', key: 'Teacher' },
    { title: 'Course', dataIndex: 'Course', key: 'Course' },
    { title: 'Test Start Date', dataIndex: 'TestStartDate', key: 'TestStartDate', sorter: (a, b) => new Date(a.TestStartDate) - new Date(b.TestStartDate) },
    { title: 'Test RIT Score', dataIndex: 'TestRITScore', key: 'TestRITScore' },
    { title: 'Test Percentile', dataIndex: 'TestPercentile', key: 'TestPercentile', sorter: (a, b) => a.TestPercentile - b.TestPercentile },
    { title: 'Achievement Quintile', dataIndex: 'AchievementQuintile', key: 'AchievementQuintile' },
    { title: 'Rapid Guessing %', dataIndex: 'RapidGuessingPercentage', key: 'RapidGuessingPercentage' },
    { title: 'Projected Growth', dataIndex: 'FallToFallProjectedGrowth', key: 'FallToFallProjectedGrowth' },
    { title: 'Observed Growth', dataIndex: 'FallToFallObservedGrowth', key: 'FallToFallObservedGrowth' },
    { title: 'Met Projected Growth', dataIndex: 'FallToFallMetProjectedGrowth', key: 'FallToFallMetProjectedGrowth' },
    // { title: 'Growth Quintile', dataIndex: 'FallToFallGrowthQuintile', key: 'FallToFallGrowthQuintile' },
  ];

  return (
    <div className="assessment-results-container">
      <Title level={3}>Achievement Fall 2024-2025</Title>
      <p>Last Update on: {lastUpdate}</p>

      <Row className="filter-row" gutter={16}>
        <Col className="filter-column" span={6}>
          <Select placeholder="Filter by Course" className="filter-select" onChange={value => setCourseFilter(value)}>
            {[...new Set(data.map(item => item.Course))].map(course => (
              <Option key={course} value={course}>{course}</Option>
            ))}
          </Select>
        </Col>
        <Col className="filter-column" span={6}>
          <Select placeholder="Filter by Teacher" className="filter-select" onChange={value => setTeacherFilter(value)}>
            {[...new Set(data.map(item => item.Teacher))].map(teacher => (
              <Option key={teacher} value={teacher}>{teacher}</Option>
            ))}
          </Select>
        </Col>
        <Col className="filter-column" span={6}>
          <Select placeholder="Filter by Class" className="filter-select" onChange={value => setClassFilter(value)}>
            {[...new Set(data.map(item => item.Class))].map(classItem => (
              <Option key={classItem} value={classItem}>{classItem}</Option>
            ))}
          </Select>
        </Col>
        <Col className="filter-column" span={6}>
          <Input placeholder="Filter by Observed Growth" className="filter-input" onChange={e => setGrowthFilter(e.target.value)} />
        </Col>
      </Row>

      <div className="graph-container">
        <ResponsiveContainer width="100%" height={100}>
          <BarChart data={getPercentileDistribution()}>
            <XAxis dataKey="range" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="percentage" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <Table
        className="assessment-table"
        dataSource={filteredData}
        columns={columns}
        rowKey="StudentID"
        pagination={{ pageSize: 10 }}
      />
    </div>
  );
};

export default AssessmentResultsTable;
